import { Action, createReducer, on } from '@ngrx/store';
import { SettingsState } from '@app/core/settings/settings.model';
import {
  SET_APPLICATION_CONFIGURATION,
  SET_BROWSER,
  SET_INITIALIZATION_COMPLETE,
} from '@app/core/settings/settings.actions';

export const initialState: SettingsState = {
  language: 'en',
  appSettings: {
    contactUsEmail: '',
  },
  initializationComplete: false,
  currentBrowser: '',
};

const reducer = createReducer(
  initialState,
  on(
    SET_APPLICATION_CONFIGURATION,
    (state: SettingsState, { appSettings }): SettingsState => ({
      ...state,
      appSettings: {
        ...state.appSettings,
        ...appSettings,
      },
    }),
  ),
  on(
    SET_INITIALIZATION_COMPLETE,
    (state: SettingsState): SettingsState => ({
      ...state,
      initializationComplete: true,
    }),
  ),
  on(
    SET_BROWSER,
    (state: SettingsState, { currentBrowser }): SettingsState => ({
      ...state,
      currentBrowser,
    }),
  ),
);

export function settingsReducer(state: SettingsState | undefined, action: Action) {
  return reducer(state, action);
}
