import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { generateMessage, LOG_FAILED } from '@app/utils/helpers/logger';
import { updateAlertStatus } from '@app/core/store/alerts/alerts.action';
import { Store } from '@ngrx/store';

/**
 * Application-wide error handler that adds a UI notification to the error handling
 * provided by the default Angular ErrorHandler.
 */
@Injectable()
export class AppErrorHandler implements ErrorHandler {
  constructor(
    private logger: NGXLogger,
    private injector: Injector,
  ) {}

  /**
   * Handles errors by logging them to the console.
   * @param error The error to handle.
   */
  handleError(error: Error): void {
    const store = this.injector.get(Store);
    store.dispatch(
      updateAlertStatus({
        data: {
          description: 'alerts.errorGeneral',
          text: 'alerts.error',
          type: 'error',
          isShowSecondaryButton: false,
          isShowPrimaryButton: false,
          show: true,
        },
      }),
    );
    this.logger.error(generateMessage('app-error-handler', LOG_FAILED, 'Error: '), error);
  }
}
