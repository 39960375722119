import { Component, Inject } from '@angular/core';
import { fontWeight } from '@app/utils/models/font-weight';
import { EFontColor } from '@app/utils/models/e-font-color';
import { EReusableComponentSizes } from '@app/utils/data/common.protocol';
import { EButtonTypes } from '@app-m-shared/models/mol-button.model';
import { DialogRef } from '@angular/cdk/dialog';
import { lastValueFrom, take } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectContactUsEmail } from '@app/core/settings/settings.selectors';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MultiSessionModalModel } from '@app/utils/models/multi-session-modal.model';

@Component({
  selector: 'app-account-login-attemp',
  templateUrl: './multi-session-modal.component.html',
  styleUrls: ['./multi-session-modal.component.scss'],
})
export class MultiSessionModalComponent {
  readonly eFontWeight = fontWeight;
  readonly eFontColor = EFontColor;
  readonly eComponentsSize = EReusableComponentSizes;
  readonly eButtonType = EButtonTypes;
  constructor(
    public dialogRef: DialogRef<MultiSessionModalComponent>,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: MultiSessionModalModel,
  ) {}
  async openMail(): Promise<void> {
    const subject = 'Email Subject';
    const body = 'Email Body';
    const recipient = await lastValueFrom(this.store.pipe(select(selectContactUsEmail), take(1)));
    window.open(`mailto:${recipient}?subject=${subject}&body=${body}`);
  }
  dismiss() {
    this.dialogRef.close();
  }
}
