import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TermsAndConditionsDialogComponent } from '@app-m-auth/terms-and-conditions/terms-and-conditions-dialog/terms-and-conditions-dialog.component';
import { TranslocoDirective, TranslocoModule } from '@ngneat/transloco';
import { AtmTextH6Module } from '@app-atoms/atm-text-h6/atm-text-h6.module';
import { AtmImageModule } from '@app-atoms/atm-image/atm-image.module';
import { AtmTextUiModule } from '@app-atoms/atm-text-ui/atm-text-ui.module';
import { AtmDividerModule } from '@app-atoms/atm-divider/atm-divider.module';
import { MolButtonModule } from '@app-molecules/mol-button/mol-button.module';
import { MolButtonLinkModule } from '@app-molecules/mol-button-link/mol-button-link.module';
import { AtmTextCaptionModule } from '@app-atoms/atm-text-caption/atm-text-caption.module';
import { OrgAlertModule } from '@app-organisms/org-alert/org-alert.module';
import { AtmIconModule } from '@app-atoms/atm-icon/atm-icon.module';
import { TermsAndConditionsComponent } from './terms-and-conditions.component';
import { TermsAndConditiosRoutingModule } from '@app-m-auth/terms-and-conditions/terms-and-conditios-routing.module';

@NgModule({
  declarations: [TermsAndConditionsDialogComponent, TermsAndConditionsComponent],
  imports: [
    TermsAndConditiosRoutingModule,
    CommonModule,
    AtmTextH6Module,
    AtmImageModule,
    AtmTextUiModule,
    AtmDividerModule,
    MolButtonModule,
    MolButtonLinkModule,
    AtmTextCaptionModule,
    TranslocoDirective,
    OrgAlertModule,
    AtmIconModule,
    TranslocoModule,
  ],
})
export class TermsAndConditionsModule {}
