import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { CatalogosService } from '@api-promsy-services';
import { Injectable } from '@angular/core';
import { catchError, EMPTY, map, mergeMap, of, switchMap } from 'rxjs';
import { QueryResultCatUnidadObj } from '@api-promsy-models';
import {
  selectNeedsToReloadCatAvailability,
  selectNeedsToReloadCatPresentationType,
  selectNeedsToReloadCatUnit,
} from '@app/core/store/catalogs/catalogs.selector';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/core.state';
import {
  empty,
  getCatAvailabilityFailed,
  getCatAvailabilityLoad,
  getCatAvailabilitySuccess,
  getCatPresentationTypeFailed,
  getCatPresentationTypeLoad,
  getCatPresentationTypeSuccess,
  getCatUnitFailed,
  getCatUnitLoad,
  getCatUnitSuccess,
} from '@app/core/store/catalogs/catalogs.action';
import { NGXLogger } from 'ngx-logger';
import { generateMessage, LOG_FAILED, LOG_SUCCEEDED } from '@app/utils/helpers/logger';
import CatUnidadQueryResultParams = CatalogosService.CatUnidadQueryResultParams;
import CatTipoPresentacionQueryResultParams = CatalogosService.CatTipoPresentacionQueryResultParams;
import CatDisponibilidadQueryResultParams = CatalogosService.CatDisponibilidadQueryResultParams;

const fileName = 'catalogs.effects';
@Injectable()
export class CatalogsEffects {
  constructor(
    private actions$: Actions,
    private catalogService: CatalogosService,
    private loggerService: NGXLogger,
    private store: Store<AppState>,
  ) {}

  /**
   * @function getCatUnit$ - Effect to get unit catalog
   * @param {boolean} NeedsToRelad - Data from selector selectNeedsToReloadCatUnit
   * @return {QueryResultCatUnidadObj} - Data for unit catalog
   * **/
  getCatUnit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCatUnitLoad),
      concatLatestFrom(() => this.store.select(selectNeedsToReloadCatUnit)),
      mergeMap(([action, needsToReload]) => {
        if (needsToReload) {
          const params: CatUnidadQueryResultParams = {
            info: {
              Filters: [
                {
                  NombreFiltro: 'Activo',
                  ValorFiltro: true,
                },
              ],
              SortDirection: 'asc',
              SortField: 'Unidad',
            },
          };

          return this.catalogService.catUnidadQueryResult(params).pipe(
            map((response: QueryResultCatUnidadObj) => {
              this.loggerService.debug(
                generateMessage(fileName, LOG_SUCCEEDED, 'Success try to get unit catalog'),
                response,
              );
              return getCatUnitSuccess({
                queryResult: response,
              });
            }),
          );
        }

        return of(empty());
      }),
      catchError((error) => {
        this.loggerService.debug(
          generateMessage(fileName, LOG_FAILED, 'Error try to get unit catalog'),
          error,
        );
        return of(getCatUnitFailed());
      }),
    );
  });

  /**
   * @function getCatPresentationType$ - Effect to get presentation type catalog
   * @param {boolean} NeedsToRelad - Data from selector selectNeedsToReloadCatPresentationType
   * @return {QueryResultCatTipoPresentacionObj} - Data for presentation type catalog
   * **/
  getCatPresentationType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCatPresentationTypeLoad),
      concatLatestFrom(() => this.store.select(selectNeedsToReloadCatPresentationType)),
      mergeMap(([action, needsToReload]) => {
        if (needsToReload) {
          const paramas: CatTipoPresentacionQueryResultParams = {
            info: {
              Filters: [
                {
                  NombreFiltro: 'Activo',
                  ValorFiltro: true,
                },
              ],
              SortDirection: 'asc',
              SortField: 'TipoPresentacion',
            },
          };
          return this.catalogService.catTipoPresentacionQueryResult(paramas).pipe(
            map((response: QueryResultCatUnidadObj) => {
              this.loggerService.debug(
                generateMessage(
                  fileName,
                  LOG_SUCCEEDED,
                  'Success try to get presentation type catalog',
                ),
                response,
              );
              return getCatPresentationTypeSuccess({ queryResult: response });
            }),
          );
        }
        return of(empty());
      }),
      catchError((error) => {
        this.loggerService.debug(
          generateMessage(fileName, LOG_FAILED, 'Error data try to get presentation type catalog'),
          error,
        );
        return of(getCatPresentationTypeFailed());
      }),
    );
  });

  /**
   * @function getCatAvailability$ - Effect to get availability catalog
   * @param {boolean} NeedsToRelad - Data from selector selectNeedsToReloadCatAvailability
   * @return {QueryResultCatUnidadObj} - Data for availability catalog
   * **/
  getCatAvailability$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getCatAvailabilityLoad),
      concatLatestFrom(() => this.store.select(selectNeedsToReloadCatAvailability)),
      switchMap(([action, needsToReload]) => {
        if (needsToReload) {
          const params: CatDisponibilidadQueryResultParams = {
            info: {
              Filters: [
                {
                  NombreFiltro: 'Activo',
                  ValorFiltro: true,
                },
              ],
              SortDirection: 'asc',
              SortField: 'Disponibilidad',
            },
          };

          return this.catalogService.catDisponibilidadQueryResult(params).pipe(
            map((response: QueryResultCatUnidadObj) => {
              this.loggerService.debug(
                generateMessage(fileName, LOG_SUCCEEDED, 'Success try to get availability catalog'),
                response,
              );
              return getCatAvailabilitySuccess({
                queryResult: response,
              });
            }),
            catchError((error) => {
              this.loggerService.debug(
                generateMessage(fileName, LOG_FAILED, 'Error data try to get availability catalog'),
                error,
              );
              return of(getCatAvailabilityFailed());
            }),
          );
        }

        return EMPTY;
      }),
      catchError((error) => {
        this.loggerService.debug(
          generateMessage(fileName, LOG_FAILED, 'Error server try to get availability catalog'),
          error,
        );
        return of(getCatAvailabilityFailed());
      }),
    );
  });
}
