// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  /*  @include d.flex-column;
  @include d.height-width-100;*/
}
:host .skeleton-flex-column {
  display: flex;
  flex-direction: column;
}
:host .skeleton-flex-row {
  display: flex;
  flex-direction: row;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/atoms/atm-skeleton/atm-skeleton.component.scss","webpack://./src/styles/_displayed.scss"],"names":[],"mappings":"AACA;EACE;+BAAA;AACF;AAEE;ECSA,aAAA;EAmBA,sBAAA;AD1BF;AACE;ECMA,aAAA;EAKA,mBAAA;ADRF","sourcesContent":["@use 'displayed' as d;\n:host {\n  /*  @include d.flex-column;\n  @include d.height-width-100;*/\n\n  .skeleton-flex-column {\n    @include d.flex-column;\n  }\n  .skeleton-flex-row {\n    @include d.flex-row;\n  }\n}\n","/// Breakpoints names\n$small: 'small';\n$standard: 'standard';\n\n\n/// Breakpoints\n$breakpoints-pqf: ($small: 0px, $standard: 1920px);\n\n@mixin height-width-100 {\n  height: 100%;\n  width: 100%;\n}\n\n@mixin _flex {\n  display: flex;\n}\n\n@mixin _flex-row {\n  @include _flex;\n  flex-direction: row;\n}\n\n@mixin flex-row-wrap {\n  @include _flex-row;\n  flex-wrap: wrap;\n}\n\n@mixin flex-row {\n  @include _flex-row;\n}\n\n@mixin flex-column {\n  @include _flex;\n  flex-direction: column;\n}\n\n@mixin flex-center-items {\n  align-items: center;\n  justify-content: center;\n}\n\n// MediaQuery\n$small-screen: 1366px;\n$medium-screen: 1920px;\n$large-screen: 2560px;\n\n@mixin custom-media-query($size) {\n  @media (min-width: $size) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
