import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TermsAndConditionsComponent } from '@app-m-auth/terms-and-conditions/terms-and-conditions.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: TermsAndConditionsComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TermsAndConditiosRoutingModule {}
