import { createSelector } from '@ngrx/store';
import { selectAuthState } from '@app/core/core.state';
import { AuthState } from '@app/utils/models/auth/auth.models';
import { CodeStatus, ConfirmCodeStatus } from '@app/utils/models/mol-confirm-code';
import { UserPromsy } from '@app/utils/models/auth/user-promsy';

export const selectStateRooterState = createSelector(selectAuthState, (state: AuthState) => state);

//Selector para obtener token
export const selectToken = createSelector(
  selectStateRooterState,
  (state: AuthState) => state.token.access_token,
);
export const selectQrCodeImage = createSelector(
  selectStateRooterState,
  (state: AuthState) => state.qrCodeImage,
);
export const selectCodeAuthStatus = createSelector(
  selectStateRooterState,
  (state: AuthState): ConfirmCodeStatus => state.codeAuthStatus,
);

export const selectWhoAmiInfo = createSelector(
  selectStateRooterState,
  (state: AuthState): UserPromsy => state?.userInfo || {},
);
export const selectIdConnectionHub = createSelector(
  selectStateRooterState,
  (state: AuthState): string => state?.idConnectionHub || '',
);
export const selectIdSession = createSelector(
  selectStateRooterState,
  (state: AuthState): string => state?.token?.idSession || '',
);
export const selectEnableProductInformationExpirationDate = createSelector(
  selectWhoAmiInfo,
  (state: UserPromsy): boolean => state.ModificaFechaCuraduriaPromsy!,
);
