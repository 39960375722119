import { createAction, props } from '@ngrx/store';
import { buildStringNameForAction } from '@app/utils/data/common.protocol';

const reducer = 'dashboard-reducer';
const service = 'dashboard-service';

export const getTotalProductsServiceLoad = createAction(
  buildStringNameForAction(reducer, 'Get Total Products Service Load'),
);

export const getTotalProductsServiceSuccess = createAction(
  buildStringNameForAction(reducer, 'Get Total Products Service Success'),
  props<{ response: number }>(),
);

export const getTotalProductsServiceFailed = createAction(
  buildStringNameForAction(reducer, 'Get Total Products Service Failed'),
);
export const setTotalProductsSystem = createAction(
  buildStringNameForAction(reducer, 'Set Total Products System'),
  props<{ value: number }>(),
);

export const updateNumberProducts = createAction(
  buildStringNameForAction(reducer, 'Update Number Products'),
  props<{ value: number }>(),
);

export const updateNumberProductsDashboardSuccess = createAction(
  buildStringNameForAction(service, 'Update Number Products Dashboard Success'),
);

export const updateNumberProductsSuccess = createAction(
  buildStringNameForAction(service, 'Update Number Products Success'),
);

export const setStatusNumberProducts = createAction(
  buildStringNameForAction(service, 'Set status Number Products'),
);

export const updateNumberProductsFailed = createAction(
  buildStringNameForAction(service, 'Update Number Products Failed'),
);
