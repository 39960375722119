import { Action, createReducer, on } from '@ngrx/store';
import { initialUtilsState, UtilsState } from '@app/core/utils/utils.model';
import { SET_APP_VERSION, SET_VIEW_TYPE } from '@app/core/utils/utils.action';

export const initialState: UtilsState = {
  ...initialUtilsState(),
};

const reducer = createReducer(
  initialState,
  on(
    SET_VIEW_TYPE,
    (state: UtilsState, { viewType, screenSize }): UtilsState => ({
      ...state,
      viewType,
      screenSize,
    }),
  ),

  on(SET_APP_VERSION, (state: UtilsState, { appVersion }): UtilsState => {
    return {
      ...state,
      appVersion,
    };
  }),
);

export function utilsReducer(state: UtilsState | undefined, action: Action): UtilsState {
  return reducer(state, action);
}
