import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MolButtonLinkComponent } from '@app-m-shared/molecules/mol-button-link/mol-button-link.component';
import { AtmTextUiModule } from '@app-atoms/atm-text-ui/atm-text-ui.module';
import { AtmTextCaptionModule } from '@app-atoms/atm-text-caption/atm-text-caption.module';
import { AtmIconModule } from '@app-atoms/atm-icon/atm-icon.module';
import { AtmTextH5Module } from '@app-atoms/atm-text-h5/atm-text-h5.module';

@NgModule({
  declarations: [MolButtonLinkComponent],
  imports: [CommonModule, AtmTextUiModule, AtmTextCaptionModule, AtmIconModule, AtmTextH5Module],
  exports: [MolButtonLinkComponent],
})
export class MolButtonLinkModule {}
