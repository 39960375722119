import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertPosition, AlertType, EAlertPosition, EAlertType } from '@app-m-shared/models/alert';
import { EIconKey, ESizesIcon, EStatusIcon, StatusIcon } from '@app-m-shared/models/atm-icon.model';
import { fontWeight } from '@app/utils/models/font-weight';
import { EFontColor, FontColor } from '@app/utils/models/e-font-color';
import { ButtonTypeKey, EButtonSizes } from '@app-m-shared/models/mol-button.model';

@Component({
  selector: 'org-alert',
  templateUrl: './org-alert.component.html',
  styleUrls: ['./org-alert.component.scss'],
})
/**
 * Organism alert
 * @description This component is for reusable alerts
 *
 * @property {AlertPosition}  position Alert position
 * @property {AlertType} type Alert type
 * @property {boolean} isShowClose Show close button
 * @property {boolean} isShowPrimaryButton Show primary button
 * @property {boolean} isShowSecondaryButton Show secondary button
 * @property {string}  description Description text
 * @property {string}  primaryButtonText Text for first button
 * @property {string}  secondaryButtonText Text for second button
 * @property {ButtonTypeKey}  primaryButtonType Background color for primary button
 * @property {ButtonTypeKey}  secondaryButtonType Background color for secondary button
 * @property {string}  text Main text
 * @property {string}  text Main text
 */
export class OrgAlertComponent {
  /** Alert position **/
  @Input() position: AlertPosition = EAlertPosition.bottom;
  /** Alert type **/
  @Input() type: AlertType = EAlertType.success;
  /** Show close button **/
  @Input() isShowClose = true;
  /** Show primary button **/
  @Input() isShowPrimaryButton = true;
  /** Show secondary button **/
  @Input() isShowSecondaryButton = true;
  /** Show link button **/
  @Input() isShowLinkButton = true;
  /** Description text **/
  @Input({ required: true }) description = '';
  /** Text for first button **/
  @Input() primaryButtonText = '';
  /** Text for second button **/
  @Input() secondaryButtonText = '';
  /** Text for link button **/
  @Input() linkButtonText = '';
  /** Background color for primary button **/
  @Input() primaryButtonType: ButtonTypeKey = 'primary';
  /** Background color for secondary button **/
  @Input() secondaryButtonType: ButtonTypeKey = 'secondary';
  /** Main text **/
  @Input({ required: true }) text = '';
  /** Event for primary button **/
  @Output() primaryButtonEvent: EventEmitter<void> = new EventEmitter<void>();
  /** Event for secondary button **/
  @Output() secondaryButtonEvent: EventEmitter<void> = new EventEmitter<void>();
  /** Event for close button **/
  @Output() closeButtonEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  /** Event for link button **/
  @Output() linkButtonEvent: EventEmitter<void> = new EventEmitter<void>();

  /** @readonly {EAlertType} Contain types for alerts **/
  readonly eAlertType = EAlertType;
  /** @readonly {EIconKey} Contain keys for atm-icon **/
  readonly eIconKey = EIconKey;
  /** @readonly {EStatusIcon} Contain status for atm-icon **/
  readonly eStatusIcon = EStatusIcon;
  /** @readonly {fontWeight} Contain font weight for atm-text-ui **/
  readonly eFontWeight = fontWeight;
  /** @readonly {fontColor} Contain font colors for atm-text-ui **/
  readonly eFontColor = EFontColor;
  /** @readonly {ESizesIcon} Contain font colors for atm-text-ui **/
  readonly eSizesIcon = ESizesIcon;
  /** @readonly {EButtonSizes} Contain font colors for atm-text-ui **/
  readonly eButtonSizes = EButtonSizes;

  /** alertIcons - Get alert icon by alert type **/
  alertIcons: { [key in EAlertType]: StatusIcon } = {
    [EAlertType.success]: this.eStatusIcon.alertSuccessGreen,
    [EAlertType.error]: this.eStatusIcon.alertRed,
    [EAlertType.warning]: this.eStatusIcon.alertYellow,
    [EAlertType.disabled]: this.eStatusIcon.alertGrey,
  };

  /** textColors - Get font color by alert type **/
  textColors: { [key in EAlertType]: FontColor } = {
    [EAlertType.success]: this.eFontColor.accentGreen,
    [EAlertType.error]: this.eFontColor.accentRed,
    [EAlertType.warning]: this.eFontColor.accentYellow,
    [EAlertType.disabled]: this.eFontColor.accentGrey,
  };

  /**
   * Method when primary button is clicked
   * @method onPrimaryButtonClick
   */
  onPrimaryButtonClick(): void {
    this.primaryButtonEvent.emit();
  }

  /**
   * Method when secondary button is clicked
   * @method onSecondaryButtonClick
   */
  onSecondaryButtonClick(): void {
    this.secondaryButtonEvent.emit();
  }

  onLinkButtonClick(): void {
    this.linkButtonEvent.emit();
  }

  /**
   * Close alert
   * @method onClose
   */
  onClose(): void {
    this.closeButtonEvent.emit(true);
  }
}
