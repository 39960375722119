/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiPromsyConfiguration as __Configuration } from '../api-promsy-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
class EjemploService extends __BaseService {
  static readonly EjemploGetPath = '/Ejemplo/{id}';
  static readonly EjemploPutPath = '/Ejemplo/{id}';
  static readonly EjemploDeletePath = '/Ejemplo/{id}';
  static readonly EjemploGet_1Path = '/Ejemplo';
  static readonly EjemploPostPath = '/Ejemplo';
  static readonly EnvioEmailEjemploGetPath = '/EnvioEmailEjemplo/{id}';
  static readonly EnvioEmailEjemploPutPath = '/EnvioEmailEjemplo/{id}';
  static readonly EnvioEmailEjemploDeletePath = '/EnvioEmailEjemplo/{id}';
  static readonly EnvioEmailEjemploGet_1Path = '/EnvioEmailEjemplo';
  static readonly EnvioEmailEjemploPostAsyncPath = '/EnvioEmailEjemplo';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Get Ejemplo
   * @param params The `EjemploService.EjemploGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  EjemploGetResponse(params: EjemploService.EjemploGetParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Ejemplo/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Get Ejemplo
   * @param params The `EjemploService.EjemploGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  EjemploGet(params: EjemploService.EjemploGetParams): __Observable<string> {
    return this.EjemploGetResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Put Ejemplo
   * @param params The `EjemploService.EjemploPutParams` containing the following parameters:
   *
   * - `value`:
   *
   * - `id`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   */
  EjemploPutResponse(params: EjemploService.EjemploPutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.value;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Ejemplo/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Put Ejemplo
   * @param params The `EjemploService.EjemploPutParams` containing the following parameters:
   *
   * - `value`:
   *
   * - `id`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   */
  EjemploPut(params: EjemploService.EjemploPutParams): __Observable<null> {
    return this.EjemploPutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Delete Ejemplo
   * @param params The `EjemploService.EjemploDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   */
  EjemploDeleteResponse(params: EjemploService.EjemploDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Ejemplo/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete Ejemplo
   * @param params The `EjemploService.EjemploDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   */
  EjemploDelete(params: EjemploService.EjemploDeleteParams): __Observable<null> {
    return this.EjemploDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get Ejemplo
   * @return OK
   */
  EjemploGet_1Response(): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Ejemplo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Get Ejemplo
   * @return OK
   */
  EjemploGet_1(): __Observable<Array<string>> {
    return this.EjemploGet_1Response().pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * Post Ejemplo
   * @param params The `EjemploService.EjemploPostParams` containing the following parameters:
   *
   * - `value`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   */
  EjemploPostResponse(params: EjemploService.EjemploPostParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.value;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Ejemplo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Post Ejemplo
   * @param params The `EjemploService.EjemploPostParams` containing the following parameters:
   *
   * - `value`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   */
  EjemploPost(params: EjemploService.EjemploPostParams): __Observable<null> {
    return this.EjemploPostResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get EnvioEmailEjemplo
   * @param params The `EjemploService.EnvioEmailEjemploGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  EnvioEmailEjemploGetResponse(params: EjemploService.EnvioEmailEjemploGetParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/EnvioEmailEjemplo/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Get EnvioEmailEjemplo
   * @param params The `EjemploService.EnvioEmailEjemploGetParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  EnvioEmailEjemploGet(params: EjemploService.EnvioEmailEjemploGetParams): __Observable<string> {
    return this.EnvioEmailEjemploGetResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Put EnvioEmailEjemplo
   * @param params The `EjemploService.EnvioEmailEjemploPutParams` containing the following parameters:
   *
   * - `value`:
   *
   * - `id`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   */
  EnvioEmailEjemploPutResponse(params: EjemploService.EnvioEmailEjemploPutParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.value;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/EnvioEmailEjemplo/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Put EnvioEmailEjemplo
   * @param params The `EjemploService.EnvioEmailEjemploPutParams` containing the following parameters:
   *
   * - `value`:
   *
   * - `id`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   */
  EnvioEmailEjemploPut(params: EjemploService.EnvioEmailEjemploPutParams): __Observable<null> {
    return this.EnvioEmailEjemploPutResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Delete EnvioEmailEjemplo
   * @param params The `EjemploService.EnvioEmailEjemploDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   */
  EnvioEmailEjemploDeleteResponse(params: EjemploService.EnvioEmailEjemploDeleteParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/EnvioEmailEjemplo/${encodeURIComponent(String(params.id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * Delete EnvioEmailEjemplo
   * @param params The `EjemploService.EnvioEmailEjemploDeleteParams` containing the following parameters:
   *
   * - `id`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   */
  EnvioEmailEjemploDelete(params: EjemploService.EnvioEmailEjemploDeleteParams): __Observable<null> {
    return this.EnvioEmailEjemploDeleteResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * Get EnvioEmailEjemplo
   * @return OK
   */
  EnvioEmailEjemploGet_1Response(): __Observable<__StrictHttpResponse<Array<string>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/EnvioEmailEjemplo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<string>>;
      })
    );
  }
  /**
   * Get EnvioEmailEjemplo
   * @return OK
   */
  EnvioEmailEjemploGet_1(): __Observable<Array<string>> {
    return this.EnvioEmailEjemploGet_1Response().pipe(
      __map(_r => _r.body as Array<string>)
    );
  }

  /**
   * PostAsync EnvioEmailEjemplo
   * @param params The `EjemploService.EnvioEmailEjemploPostAsyncParams` containing the following parameters:
   *
   * - `value`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  EnvioEmailEjemploPostAsyncResponse(params: EjemploService.EnvioEmailEjemploPostAsyncParams): __Observable<__StrictHttpResponse<{}>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.value;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/EnvioEmailEjemplo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<{}>;
      })
    );
  }
  /**
   * PostAsync EnvioEmailEjemplo
   * @param params The `EjemploService.EnvioEmailEjemploPostAsyncParams` containing the following parameters:
   *
   * - `value`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  EnvioEmailEjemploPostAsync(params: EjemploService.EnvioEmailEjemploPostAsyncParams): __Observable<{}> {
    return this.EnvioEmailEjemploPostAsyncResponse(params).pipe(
      __map(_r => _r.body as {})
    );
  }
}

module EjemploService {

  /**
   * Parameters for EjemploGet
   */
  export interface EjemploGetParams {
    id: number;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for EjemploPut
   */
  export interface EjemploPutParams {
    value: string;
    id: number;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for EjemploDelete
   */
  export interface EjemploDeleteParams {
    id: number;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for EjemploPost
   */
  export interface EjemploPostParams {
    value: string;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for EnvioEmailEjemploGet
   */
  export interface EnvioEmailEjemploGetParams {
    id: number;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for EnvioEmailEjemploPut
   */
  export interface EnvioEmailEjemploPutParams {
    value: string;
    id: number;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for EnvioEmailEjemploDelete
   */
  export interface EnvioEmailEjemploDeleteParams {
    id: number;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for EnvioEmailEjemploPostAsync
   */
  export interface EnvioEmailEjemploPostAsyncParams {
    value: string;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }
}

export { EjemploService }
