import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MolButtonComponent } from './mol-button.component';
import { ReactiveFormsModule } from '@angular/forms';
import { AtmTextUiModule } from '@app-atoms/atm-text-ui/atm-text-ui.module';
import { AtmIconModule } from '@app-atoms/atm-icon/atm-icon.module';

@NgModule({
  declarations: [MolButtonComponent],
  imports: [CommonModule, ReactiveFormsModule, AtmTextUiModule, AtmIconModule],
  exports: [MolButtonComponent],
})
export class MolButtonModule {}
