import { createReducer, on } from '@ngrx/store';
import { CatalogsState, initialCatalogsState } from '@app/utils/models/catalogs/catalogs.model';
import {
  getCatPresentationTypeLoad,
  getCatPresentationTypeFailed,
  getCatPresentationTypeSuccess,
  getCatUnitLoad,
  getCatUnitFailed,
  getCatUnitSuccess,
  getCatAvailabilityLoad,
  getCatAvailabilitySuccess,
  getCatAvailabilityFailed,
} from '@app/core/store/catalogs/catalogs.action';
import { EApiStatus } from '@app/utils/data/common.protocol';

export const catalogsReducer = createReducer(
  initialCatalogsState(),
  on(
    getCatUnitLoad,
    (state: CatalogsState): CatalogsState => ({
      ...state,
      catUnit: {
        ...state.catUnit,
        apiStatus: state.catUnit.needsToReload ? EApiStatus.loading : EApiStatus.default,
      },
    }),
  ),
  on(
    getCatUnitSuccess,
    (state: CatalogsState, { queryResult }): CatalogsState => ({
      ...state,
      catUnit: {
        apiStatus: EApiStatus.success,
        data: queryResult,
        needsToReload: false,
      },
    }),
  ),
  on(
    getCatUnitFailed,
    (state: CatalogsState): CatalogsState => ({
      ...state,
      catUnit: {
        ...state.catUnit,
        apiStatus: EApiStatus.failed,
      },
    }),
  ),
  on(
    getCatPresentationTypeLoad,
    (state: CatalogsState): CatalogsState => ({
      ...state,
      catPresentationType: {
        ...state.catPresentationType,
        apiStatus: state.catPresentationType.needsToReload
          ? EApiStatus.loading
          : EApiStatus.default,
      },
    }),
  ),
  on(
    getCatPresentationTypeSuccess,
    (state: CatalogsState, { queryResult }): CatalogsState => ({
      ...state,
      catPresentationType: {
        apiStatus: EApiStatus.success,
        data: queryResult,
        needsToReload: false,
      },
    }),
  ),
  on(
    getCatPresentationTypeFailed,
    (state: CatalogsState): CatalogsState => ({
      ...state,
      catPresentationType: {
        ...state.catPresentationType,
        apiStatus: EApiStatus.failed,
      },
    }),
  ),
  on(
    getCatAvailabilityLoad,
    (state: CatalogsState): CatalogsState => ({
      ...state,

      catAvailability: {
        ...state.catAvailability,
        apiStatus: state.catAvailability.needsToReload ? EApiStatus.loading : EApiStatus.default,
      },
    }),
  ),
  on(
    getCatAvailabilitySuccess,
    (state: CatalogsState, { queryResult }): CatalogsState => ({
      ...state,
      catAvailability: {
        apiStatus: EApiStatus.success,
        data: queryResult,
        needsToReload: false,
      },
    }),
  ),
  on(
    getCatAvailabilityFailed,
    (state: CatalogsState): CatalogsState => ({
      ...state,
      catAvailability: {
        ...state.catAvailability,
        apiStatus: EApiStatus.failed,
      },
    }),
  ),
);
