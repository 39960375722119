import { ProductoPromsy } from '@api-promsy-models';
import {
  ApiStatusTypes,
  buildTimeZone,
  DEFAULT_UUID,
  EApiStatus,
} from '@app/utils/data/common.protocol';

export interface FormsState {
  product: ProductForm;
}

export interface ProductForm {
  apiStatus: ApiStatusTypes;
  info: ProductoPromsy;
  isValidatingCas?: EApiStatus;
  isValidCas?: boolean;
}

export const initialProductForm = (): ProductForm => ({
  apiStatus: EApiStatus.default,
  info: {
    IdProducto: DEFAULT_UUID,
    ZonaHoraria: buildTimeZone(),
  },
});

export const initialFormsState = (): FormsState => ({
  product: initialProductForm(),
});
