// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: flex;
  flex-direction: row;
  height: max-content;
  width: max-content;
}
:host .small {
  height: 32px;
  width: 32px;
}
:host .medium {
  height: 40px;
  width: 40px;
}
:host .large {
  height: 48px;
  width: 48px;
}
:host .xl {
  height: 56px;
  width: 56px;
}
:host .xl2 {
  height: 64px;
  width: 64px;
}
:host .xl3 {
  height: 80px;
  width: 80px;
}
:host .xl4 {
  height: 96px;
  width: 96px;
}
:host .display-none {
  display: none;
}
:host .display-block {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/atoms/atm-image/atm-image.component.scss","webpack://./src/styles/_displayed.scss"],"names":[],"mappings":"AAEA;ECYE,aAAA;EAKA,mBAAA;EDfA,mBAAA;EACA,kBAAA;AAAF;AACE;EACE,YAAA;EACA,WAAA;AACJ;AACE;EACE,YAAA;EACA,WAAA;AACJ;AACE;EACE,YAAA;EACA,WAAA;AACJ;AACE;EACE,YAAA;EACA,WAAA;AACJ;AACE;EACE,YAAA;EACA,WAAA;AACJ;AACE;EACE,YAAA;EACA,WAAA;AACJ;AACE;EACE,YAAA;EACA,WAAA;AACJ;AACE;EACE,aAAA;AACJ;AACE;EACE,cAAA;AACJ","sourcesContent":["@use 'displayed' as d;\n\n:host {\n  @include d.flex-row;\n  height: max-content;\n  width: max-content;\n  .small {\n    height: 32px;\n    width: 32px;\n  }\n  .medium {\n    height: 40px;\n    width: 40px;\n  }\n  .large {\n    height: 48px;\n    width: 48px;\n  }\n  .xl {\n    height: 56px;\n    width: 56px;\n  }\n  .xl2 {\n    height: 64px;\n    width: 64px;\n  }\n  .xl3 {\n    height: 80px;\n    width: 80px;\n  }\n  .xl4 {\n    height: 96px;\n    width: 96px;\n  }\n  .display-none {\n    display: none;\n  }\n  .display-block {\n    display: block;\n  }\n}\n","/// Breakpoints names\n$small: 'small';\n$standard: 'standard';\n\n\n/// Breakpoints\n$breakpoints-pqf: ($small: 0px, $standard: 1920px);\n\n@mixin height-width-100 {\n  height: 100%;\n  width: 100%;\n}\n\n@mixin _flex {\n  display: flex;\n}\n\n@mixin _flex-row {\n  @include _flex;\n  flex-direction: row;\n}\n\n@mixin flex-row-wrap {\n  @include _flex-row;\n  flex-wrap: wrap;\n}\n\n@mixin flex-row {\n  @include _flex-row;\n}\n\n@mixin flex-column {\n  @include _flex;\n  flex-direction: column;\n}\n\n@mixin flex-center-items {\n  align-items: center;\n  justify-content: center;\n}\n\n// MediaQuery\n$small-screen: 1366px;\n$medium-screen: 1920px;\n$large-screen: 2560px;\n\n@mixin custom-media-query($size) {\n  @media (min-width: $size) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
