import { environment } from '@environments/environment';

const PATH = environment.appBaseHref + '/assets/images/icons/';

/**
 * Represents the possible keys for status icons.
 * @typedef {(alertGrey | alertRed | alertSuccessGreen | alertSuccessGrey | alertYellow | default | disable | dotGreen | dotGrey | dotLarge | dotMedium | dotPurple | dotSmall | green | hover | red | white | yellow)} StatusIcon
 * @description
 * - 'alertGrey': Grey alert icon.
 * - 'alertRed': Red alert icon.
 * - 'alertSuccessGreen': Successful green alert icon.
 * - 'alertSuccessGrey': Successful grey alert icon.
 * - 'alertYellow': Yellow alert icon.
 * - 'default': Default icon.
 * - 'disable': Disabled icon.
 * - 'green': green icon.
 * - 'red': red icon.
 * - 'white': White icon.
 * - 'yellow': yellow icon.
 * - 'orange': orange icon.
 * @memberof StatusIcon
 */
export type StatusIcon =
  | 'alertGrey'
  | 'alertRed'
  | 'alertSuccessGreen'
  | 'alertSuccessGrey'
  | 'alertYellow'
  | 'blue'
  | 'default'
  | 'disable'
  | 'green'
  | 'grey'
  | 'hover'
  | 'purple'
  | 'red'
  | 'white'
  | 'yellow'
  | 'orange';

/**
 * Enum representing status icon keys with corresponding values.
 * @enum {string}
 */
export enum EStatusIcon {
  alertGrey = 'alertGrey',
  alertRed = 'alertRed',
  alertSuccessGreen = 'alertSuccessGreen',
  alertSuccessGrey = 'alertSuccessGrey',
  alertYellow = 'alertYellow',
  blue = 'blue',
  default = 'default',
  disable = 'disable',
  green = 'green',
  grey = 'grey',
  hover = 'hover',
  purple = 'purple',
  red = 'red',
  white = 'white',
  yellow = 'yellow',
  orange = 'orange',
}
/**
 * Data type to specify sizes for icon elements.
 *
 * @type {('36' | '24' | '20' | '16' | '12')} SizesIcon
 * @description Available options:
 * - '36': Represents a size of 36 pixels.
 * - '24': Represents a size of 24 pixels.
 * - '20': Represents a size of 20 pixels.
 * - '16': Represents a size of 16 pixels.
 * - '12': Represents a size of 12 pixels.
 * @memberof SizesIcon
 */
export type SizesIcon = '36' | '24' | '20' | '16' | '12';

/**
 * Enum representing the sizes of icons.
 * @enum {string}
 */
export enum ESizesIcon {
  Size12 = '12',
  Size16 = '16',
  Size20 = '20',
  Size24 = '24',
  Size36 = '36',
}
/**
 * Interface representing status icon paths for different states.
 * @interface StatusIconGeneral
 * @property {string} default - Path for the default state icon.
 * @property {string} disable - Path for the disabled state icon.
 * @property {string} hover - Path for the hover state icon.
 * @property {string} white - Path for the white state icon.
 */

interface StatusIconGeneral {
  default?: string;
  disable?: string;
  hover?: string;
  white?: string;
}
interface StatusSpecialsIcon extends StatusIconGeneral {
  orange?: string;
  red?: string;
  green?: string;
  yellow?: string;
  purple?: string;
  grey?: string;
  blue?: string;
}
/**
 * Interface representing status alert icon paths for different alert types.
 * @interface StatusAlertIcon
 * @property {string} alertRed - Path for the red alert icon.
 * @property {string} alertGrey - Path for the grey alert icon.
 * @property {string} alertYellow - Path for the yellow alert icon.
 * @property {string} alertSuccessGreen - Path for the success green alert icon.
 * @property {string} alertSuccessGrey - Path for the success grey alert icon.
 */

interface StatusAlertIcon {
  alertGrey: string;
  alertRed: string;
  alertSuccessGreen: string;
  alertSuccessGrey: string;
  alertYellow: string;
}

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} AddIcon
 */

const AddIcon: StatusIconGeneral = {
  default: PATH + 'add_default.svg',
  disable: PATH + 'add_disable.svg',
  hover: PATH + 'add_hover.svg',
  white: PATH + 'add_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} AddPromsyIcon
 */
const AddPromsyIcon: StatusIconGeneral = {
  default: PATH + 'add_promsy_default.svg',
  disable: PATH + 'add_promsy_disable.svg',
  hover: PATH + 'add_promsy_hover.svg',
  white: PATH + 'add_promsy_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} AlertIcon
 */
const AlertIcon: StatusAlertIcon = {
  alertGrey: PATH + 'alert_grey.svg',
  alertRed: PATH + 'alert_red.svg',
  alertSuccessGreen: PATH + 'alert_success_green.svg',
  alertSuccessGrey: PATH + 'alert_success_grey.svg',
  alertYellow: PATH + 'alert_yellow.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} ArrowDownIcon
 */
const ArrowDownIcon: StatusIconGeneral = {
  default: PATH + 'arrow_down_default.svg',
  disable: PATH + 'arrow_down_disable.svg',
  hover: PATH + 'arrow_down_hover.svg',
  white: PATH + 'arrow_down_white.svg',
};

/**
 * Add Promsy icon paths for different states.
 * @const {StatusIconGeneral} ArrowSortIcon
 */
const ArrowSortIcon: StatusIconGeneral = {
  default: PATH + 'arrow_sort_default.svg',
  disable: PATH + 'arrow_sort_disable.svg',
  hover: PATH + 'arrow_sort_hover.svg',
  white: PATH + 'arrow_sort_white.svg',
};

/**
 * Add Promsy icon paths for different states.
 * @const {StatusIconGeneral} arrowLeftPageIcon
 */
const arrowLeftPageIcon: StatusIconGeneral = {
  default: PATH + 'arrow_left_page_default.svg',
  disable: PATH + 'arrow_left_page_disable.svg',
  hover: PATH + 'arrow_left_page_hover.svg',
  white: PATH + 'arrow_left_page_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} arrowLeftIcon
 */
const arrowLeftIcon: StatusIconGeneral = {
  default: PATH + 'arrow_left_default.svg',
  disable: PATH + 'arrow_left_disable.svg',
  hover: PATH + 'arrow_left_hover.svg',
  white: PATH + 'arrow_left_white.svg',
};
/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} ArrowRightPageIcon
 */
const ArrowRightPageIcon: StatusIconGeneral = {
  default: PATH + 'arrow_right_page_default.svg',
  disable: PATH + 'arrow_right_page_disable.svg',
  hover: PATH + 'arrow_right_page_hover.svg',
  white: PATH + 'arrow_right_page_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} ArrowRightIcon
 */
const ArrowRightIcon: StatusIconGeneral = {
  default: PATH + 'arrow_right_default.svg',
  disable: PATH + 'arrow_right_disable.svg',
  hover: PATH + 'arrow_right_hover.svg',
  white: PATH + 'arrow_right_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} ArrowUpIcon
 */
const ArrowUpIcon: StatusIconGeneral = {
  default: PATH + 'arrow_up_default.svg',
  disable: PATH + 'arrow_up_disable.svg',
  hover: PATH + 'arrow_up_hover.svg',
  white: PATH + 'arrow_up_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} AuthenticationKeyIcon
 */
const AuthenticationKeyIcon: StatusIconGeneral = {
  default: PATH + 'auth_key_default.svg',
  disable: PATH + 'auth_key_disable.svg',
  hover: PATH + 'auth_key_hover.svg',
  white: PATH + 'auth_key_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} CalendarIcon
 */
const CalendarIcon: StatusIconGeneral = {
  default: PATH + 'calendar_default.svg',
  disable: PATH + 'calendar_disable.svg',
  hover: PATH + 'calendar_hover.svg',
  white: PATH + 'calendar_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} CalendarBusyIcon
 */
const CalendarBusyIcon: StatusIconGeneral = {
  default: PATH + 'calendar_busy_default.svg',
  disable: PATH + 'calendar_busy_disable.svg',
  hover: PATH + 'calendar_busy_hover.svg',
  white: PATH + 'calendar_busy_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} CalendarClockIcon
 */
const CalendarClockIcon: StatusIconGeneral = {
  default: PATH + 'calendar_clock_default.svg',
  disable: PATH + 'calendar_clock_disable.svg',
  hover: PATH + 'calendar_clock_hover.svg',
  white: PATH + 'calendar_clock_white.svg',
};

/**
 * Add Promsy icon paths for different states.
 * @const {StatusIconGeneral} CheckIcon
 */
const CheckIcon: StatusIconGeneral = {
  default: PATH + 'check_default.svg',
  disable: PATH + 'check_disable.svg',
  hover: PATH + 'check_hover.svg',
  white: PATH + 'check_white.svg',
};

const CheckOptionGreenIcon: StatusIconGeneral = {
  default: PATH + 'check_option_green.svg',
  disable: PATH + 'check_disable.svg',
  hover: PATH + 'check_option_green_hover.svg',
  white: PATH + 'check_white.svg',
};

const CheckChipGreenIcon: StatusIconGeneral = {
  default: PATH + 'check_chip_green.svg',
  disable: PATH + 'check_chip_green.svg',
  hover: PATH + 'check_chip_green.svg',
  white: PATH + 'check_chip_green.svg',
};
/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} CloseIcon
 */
const CloseIcon: StatusIconGeneral = {
  default: PATH + 'close_default.svg',
  disable: PATH + 'close_disable.svg',
  hover: PATH + 'close_hover.svg',
  white: PATH + 'close_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} ContainerIcon
 */
const ContainerIcon: StatusIconGeneral = {
  default: PATH + 'container_default.svg',
  disable: PATH + 'container_disable.svg',
  hover: PATH + 'container_hover.svg',
  white: PATH + 'container_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} ArrowDecreaseOutwardIcon
 */
const ArrowDecreaseOutwardIcon: StatusSpecialsIcon = {
  default: PATH + 'arrow_decrease_outward_default.svg',
  disable: PATH + 'arrow_decrease_outward_disable.svg',
  hover: PATH + 'arrow_decrease_outward_hover.svg',
  red: PATH + 'arrow_decrease_outward_red.svg',
  white: PATH + 'arrow_decrease_outward_white.svg',
  yellow: PATH + 'arrow_decrease_outward_yellow.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} DoubleArrowLeftIcon
 */
const DoubleArrowLeftIcon: StatusIconGeneral = {
  default: PATH + 'double_arrow_left_default.svg',
  disable: PATH + 'double_arrow_left_disable.svg',
  hover: PATH + 'double_arrow_left_hover.svg',
  white: PATH + 'double_arrow_left_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} DoubleArrowRightIcon
 */
const DoubleArrowRightIcon: StatusIconGeneral = {
  default: PATH + 'double_arrow_right_default.svg',
  disable: PATH + 'double_arrow_right_disable.svg',
  hover: PATH + 'double_arrow_right_hover.svg',
  white: PATH + 'double_arrow_right_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} DiscontinuedIcon
 */
const DiscontinuedIcon: StatusIconGeneral = {
  default: PATH + 'discontinued_default.svg',
  disable: PATH + 'discontinued_disable.svg',
  hover: PATH + 'discontinued_hover.svg',
  white: PATH + 'discontinued_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} EditIcon
 */
const EditIcon: StatusIconGeneral = {
  default: PATH + 'edit_default.svg',
  disable: PATH + 'edit_disable.svg',
  hover: PATH + 'edit_hover.svg',
  white: PATH + 'edit_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} ErrorIcon
 */
const ErrorIcon: StatusSpecialsIcon = {
  default: PATH + 'error_default.svg',
  disable: PATH + 'error_disable.svg',
  hover: PATH + 'error_hover.svg',
  red: PATH + 'error_red.svg',
  white: PATH + 'error_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} ArrowExpandLessIcon
 */
const ArrowExpandLessIcon: StatusIconGeneral = {
  default: PATH + 'arrow_expand_less_default.svg',
  disable: PATH + 'arrow_expand_less_disable.svg',
  hover: PATH + 'arrow_expand_less_hover.svg',
  white: PATH + 'arrow_expand_less_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} ArrowExpandMoreIcon
 */
const ArrowExpandMoreIcon: StatusIconGeneral = {
  default: PATH + 'arrow_expand_more_default.svg',
  disable: PATH + 'arrow_expand_more_disable.svg',
  hover: PATH + 'arrow_expand_more_hover.svg',
  white: PATH + 'arrow_expand_more_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusSpecialsIcon} DotSmallIcon
 */
const DotSmallIcon: StatusSpecialsIcon = {
  default: PATH + 'dot_small_gray.svg',
  hover: PATH + 'dot_small_gray.svg',
  red: PATH + 'dot_small_red.svg',
  green: PATH + 'dot_small_green.svg',
  yellow: PATH + 'dot_small_yellow.svg',
  grey: PATH + 'dot_small_gray.svg',
  purple: PATH + 'dot_small_purple.svg',
  blue: PATH + 'dot_small_blue.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusSpecialsIcon} DotMediumIcon
 */
const DotMediumIcon: StatusSpecialsIcon = {
  default: PATH + 'dot_medium_gray.svg',
  hover: PATH + 'dot_medium_gray.svg',
  red: PATH + 'dot_medium_red.svg',
  green: PATH + 'dot_medium_green.svg',
  yellow: PATH + 'dot_medium_yellow.svg',
  grey: PATH + 'dot_medium_gray.svg',
  purple: PATH + 'dot_medium_purple.svg',
  blue: PATH + 'dot_medium_blue.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusSpecialsIcon} DotLargeIcon
 */
const DotLargeIcon: StatusSpecialsIcon = {
  default: PATH + 'dot_large_gray.svg',
  hover: PATH + 'dot_large_gray.svg',
  red: PATH + 'dot_large_red.svg',
  green: PATH + 'dot_large_green.svg',
  yellow: PATH + 'dot_large_yellow.svg',
  grey: PATH + 'dot_large_gray.svg',
  purple: PATH + 'dot_large_purple.svg',
  blue: PATH + 'dot_large_blue.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} FilterIcon
 */
const FilterIcon: StatusIconGeneral = {
  default: PATH + 'filter_default.svg',
  disable: PATH + 'filter_disable.svg',
  hover: PATH + 'filter_hover.svg',
  white: PATH + 'filter_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} IncreaseArrowIcon
 */
const IncreaseArrowIcon: StatusSpecialsIcon = {
  default: PATH + 'arrow_increase_outward_default.svg',
  disable: PATH + 'arrow_increase_outward_disable.svg',
  green: PATH + 'arrow_increase_outward_green.svg',
  hover: PATH + 'arrow_increase_outward_hover.svg',
  white: PATH + 'arrow_increase_outward_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} InfoIcon
 */
const InfoIcon: StatusIconGeneral = {
  default: PATH + 'info_default.svg',
  disable: PATH + 'info_disable.svg',
  hover: PATH + 'info_hover.svg',
  white: PATH + 'info_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} AvailabilityIcon
 */
const AvailabilityIcon: StatusIconGeneral = {
  default: PATH + 'availability_default.svg',
  disable: PATH + 'availability_disable.svg',
  hover: PATH + 'availability_hover.svg',
  white: PATH + 'availability_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} AwaitingApprovalIcon
 */
const AwaitingApprovalIcon: StatusIconGeneral = {
  default: PATH + 'awaiting_approval_default.svg',
  disable: PATH + 'awaiting_approval_disable.svg',
  hover: PATH + 'awaiting_approval_hover.svg',
  white: PATH + 'awaiting_approval_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} InventoryIcon
 */
const InventoryIcon: StatusIconGeneral = {
  default: PATH + 'inventory_default.svg',
  disable: PATH + 'inventory_disable.svg',
  hover: PATH + 'inventory_hover.svg',
  white: PATH + 'inventory_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} LogoutIcon
 */
const LogoutIcon: StatusIconGeneral = {
  default: PATH + 'logout_default.svg',
  disable: PATH + 'logout_disable.svg',
  hover: PATH + 'logout_hover.svg',
  white: PATH + 'logout_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} NonMarketableIcon
 */
const NonMarketableIcon: StatusSpecialsIcon = {
  default: PATH + 'non_marketable_default.svg',
  disable: PATH + 'non_marketable_disable.svg',
  hover: PATH + 'non_marketable_hover.svg',
  white: PATH + 'non_marketable_white.svg',
  orange: PATH + 'non_marketable_orange.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} NonMarketableOrangeIcon
 */
const NonMarketableOrangeIcon: StatusIconGeneral = {
  default: PATH + 'non_marketable_orange.svg',
  disable: PATH + 'non_marketable_orange.svg',
  hover: PATH + 'non_marketable_orange.svg',
  white: PATH + 'non_marketable_orange.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusSpecialsIcon} ProductIcon
 */
const ProductIcon: StatusSpecialsIcon = {
  default: PATH + 'product_default.svg',
  disable: PATH + 'product_disable.svg',
  hover: PATH + 'product_hover.svg',
  white: PATH + 'product_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusSpecialsIcon} ProductPurpleIcon
 */
const ProductPurpleIcon: StatusSpecialsIcon = {
  default: PATH + 'product_purple.svg',
  disable: PATH + 'product_purple.svg',
  hover: PATH + 'product_purple.svg',
  white: PATH + 'product_purple.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} RemoveIcon
 */
const RemoveIcon: StatusIconGeneral = {
  default: PATH + 'remove_default.svg',
  disable: PATH + 'remove_disable.svg',
  hover: PATH + 'remove_hover.svg',
  white: PATH + 'remove_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} SearchIcon
 */
const SearchIcon: StatusIconGeneral = {
  default: PATH + 'search_default.svg',
  disable: PATH + 'search_disable.svg',
  hover: PATH + 'search_hover.svg',
  white: PATH + 'search_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} SettingsIcon
 */
const SettingsIcon: StatusIconGeneral = {
  default: PATH + 'settings_default.svg',
  disable: PATH + 'settings_disable.svg',
  hover: PATH + 'settings_hover.svg',
  white: PATH + 'settings_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} UpdateIcon
 */
const UpdateIcon: StatusIconGeneral = {
  default: PATH + 'update_default.svg',
  disable: PATH + 'update_disable.svg',
  hover: PATH + 'update_hover.svg',
  white: PATH + 'update_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} UpdateIcon
 */
const UserIcon: StatusIconGeneral = {
  default: PATH + 'user_default.svg',
  disable: PATH + 'user_disable.svg',
  hover: PATH + 'user_hover.svg',
  white: PATH + 'user_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} VisibilityIcon
 */
const VisibilityIcon: StatusIconGeneral = {
  default: PATH + 'visibility_default.svg',
  disable: PATH + 'visibility_disable.svg',
  hover: PATH + 'visibility_hover.svg',
  white: PATH + 'visibility_white.svg',
};

/**
 * Icons paths for different states.
 * @const {StatusIconGeneral} VisibilityOffIcon
 */
const VisibilityOffIcon: StatusIconGeneral = {
  default: PATH + 'visibility_off_default.svg',
  disable: PATH + 'visibility_off_disable.svg',
  hover: PATH + 'visibility_off_hover.svg',
  white: PATH + 'visibility_off_white.svg',
};

export const Icons: any = {
  add: AddIcon,
  addPromsy: AddPromsyIcon,
  alert: AlertIcon,
  arrowDecreaseOutward: ArrowDecreaseOutwardIcon,
  arrowDown: ArrowDownIcon,
  arrowExpandLess: ArrowExpandLessIcon,
  arrowExpandMore: ArrowExpandMoreIcon,
  arrowLeft: arrowLeftIcon,
  arrowLeftPage: arrowLeftPageIcon,
  arrowRightPage: ArrowRightPageIcon,
  arrowRight: ArrowRightIcon,
  arrowSort: ArrowSortIcon,
  arrowUp: ArrowUpIcon,
  authenticationKey: AuthenticationKeyIcon,
  availability: AvailabilityIcon,
  awaitingApproval: AwaitingApprovalIcon,
  calendar: CalendarIcon,
  calendarBusy: CalendarBusyIcon,
  calendarClock: CalendarClockIcon,
  check: CheckIcon,
  checkOptionGreen: CheckOptionGreenIcon,
  checkChipGreen: CheckChipGreenIcon,
  close: CloseIcon,
  container: ContainerIcon,
  discontinued: DiscontinuedIcon,
  dotSmall: DotSmallIcon,
  dotMedium: DotMediumIcon,
  dotLarge: DotLargeIcon,
  doubleArrowLeft: DoubleArrowLeftIcon,
  doubleArrowRight: DoubleArrowRightIcon,
  edit: EditIcon,
  error: ErrorIcon,
  filter: FilterIcon,
  increaseArrow: IncreaseArrowIcon,
  info: InfoIcon,
  inventory: InventoryIcon,
  logout: LogoutIcon,
  nonMarketable: NonMarketableIcon,
  nonMarketableOrange: NonMarketableOrangeIcon,
  product: ProductIcon,
  productPurple: ProductPurpleIcon,
  remove: RemoveIcon,
  search: SearchIcon,
  settings: SettingsIcon,
  update: UpdateIcon,
  user: UserIcon,
  visibility: VisibilityIcon,
  visibilityOff: VisibilityOffIcon,
};

/**
 * Data type representing different icon keys.
 *
 * @typedef {('' | 'add' | 'addPromsy' | 'alert' | 'arrowDecreaseOutward' | 'arrowDown' | 'arrowExpandLess' | 'arrowExpandMore' | 'arrowLeft' | 'arrowRightPage' | 'arrowRight' | 'arrowSort' | 'arrowUp' | 'authenticationKey' | 'availability' | 'calendar' | 'calendarBusy' | 'calendarClick' | 'check' | 'close' | 'container' | 'discontinued' | 'dot' | 'doubleArrowLeft' | 'doubleArrowRight' | 'edit' | 'error' | 'filter' | 'increaseArrow' | 'info' | 'inventory' | 'authLogoutLoad' | 'product' | 'remove' | 'search' | 'settings' | 'update' | 'visibility' | 'visibilityOff')} IconKey
 * @description
 * - '': No specific icon key.
 * - 'add': Add icon.
 * - 'addPromsy': Add Promsy icon.
 * - 'alert': Alert icon.
 * - 'arrowDecreaseOutward': Outward decrease arrow icon.
 * - 'arrowDown': Down arrow icon.
 * - 'arrowExpandLess': Expand less arrow icon.
 * - 'arrowExpandMore': Expand more arrow icon.
 * - 'arrowLeftPage': Left arrow icon.
 * - 'arrowRightPage': Right arrow icon.
 * - 'arrowRight': Change right arrow icon.
 * - 'arrowSort': Sort arrow icon.
 * - 'arrowUp': Up arrow icon.
 * - 'authenticationKey': Authentication key icon.
 * - 'availability': Availability icon.
 * - 'calendar': Calendar icon.
 * - 'calendarBusy': Busy calendar icon.
 * - 'calendarClock': Click calendar icon.
 * - 'check': Check icon.
 * - 'checkOptionGreen': check Option Green Icon.
 * - 'close': Close icon.
 * - 'container': Container icon.
 * - 'discontinued': Discontinued icon.
 * - 'dotSmall': Dot small icon.
 * - 'dotMedium': Dot medium icon.
 * - 'dotLarge': Dot large icon.
 * - 'doubleArrowLeft': Double left arrow icon.
 * - 'doubleArrowRight': Double right arrow icon.
 * - 'edit': Edit icon.
 * - 'error': Error icon.
 * - 'filter': Filter icon.
 * - 'increaseArrow': Increase arrow icon.
 * - 'info': Info icon.
 * - 'inventory': Inventory icon.
 * - 'authLogoutLoad': Logout icon.
 * - 'nonMarketable': non-Marketable icon.
 * - 'nonMarketableOrange': non-Marketable Orange icon.
 * - 'product': Product icon.
 * - 'remove': Remove icon.
 * - 'search': Search icon.
 * - 'settings': Settings icon.
 * - 'update': Update icon.
 * - 'user': User Icon.
 * - 'visibility': Visibility icon.
 * - 'visibilityOff': Visibility off icon.
 * @memberof IconKey
 */

export type IconKey =
  | ''
  | 'add'
  | 'addPromsy'
  | 'alert'
  | 'arrowDecreaseOutward'
  | 'arrowDown'
  | 'arrowExpandLess'
  | 'arrowExpandMore'
  | 'arrowLeftPage'
  | 'arrowLeft'
  | 'arrowRightPage'
  | 'arrowRight'
  | 'arrowSort'
  | 'arrowUp'
  | 'authenticationKey'
  | 'availability'
  | 'awaitingApproval'
  | 'calendar'
  | 'calendarBusy'
  | 'calendarClock'
  | 'check'
  | 'checkOptionGreen'
  | 'checkChipGreen'
  | 'close'
  | 'container'
  | 'discontinued'
  | 'dotSmall'
  | 'dotMedium'
  | 'dotLarge'
  | 'doubleArrowLeft'
  | 'doubleArrowRight'
  | 'edit'
  | 'error'
  | 'filter'
  | 'increaseArrow'
  | 'info'
  | 'inventory'
  | 'logout'
  | 'nonMarketable'
  | 'nonMarketableOrange'
  | 'product'
  | 'productPurple'
  | 'remove'
  | 'search'
  | 'settings'
  | 'update'
  | 'user'
  | 'visibility'
  | 'visibilityOff';

/**
 * @enum EIconKey representing various icon keys.
 */
export enum EIconKey {
  add = 'add',
  addPromsy = 'addPromsy',
  alert = 'alert',
  arrowDecreaseOutward = 'arrowDecreaseOutward',
  arrowDown = 'arrowDown',
  arrowExpandLess = 'arrowExpandLess',
  arrowExpandMore = 'arrowExpandMore',
  arrowLeft = 'arrowLeft',
  arrowLeftPage = 'arrowLeftPage',
  arrowRightPage = 'arrowRightPage',
  arrowRight = 'arrowRight',
  arrowSort = 'arrowSort',
  arrowUp = 'arrowUp',
  authenticationKey = 'authenticationKey',
  availability = 'availability',
  awaitingApproval = 'awaitingApproval',
  calendar = 'calendar',
  calendarBusy = 'calendarBusy',
  calendarClock = 'calendarClock',
  check = 'check',
  checkOptionGreen = 'checkOptionGreen',
  checkChipGreen = 'checkChipGreen',
  close = 'close',
  container = 'container',
  discontinued = 'discontinued',
  dotSmall = 'dotSmall',
  dotMedium = 'dotMedium',
  dotLarge = 'dotLarge',
  doubleArrowLeft = 'doubleArrowLeft',
  doubleArrowRight = 'doubleArrowRight',
  edit = 'edit',
  error = 'error',
  filter = 'filter',
  increaseArrow = 'increaseArrow',
  info = 'info',
  inventory = 'inventory',
  logout = 'logout',
  nonMarketableOrange = 'nonMarketableOrange',
  nonMarketable = 'nonMarketable',
  none = '',
  product = 'product',
  productPurple = 'productPurple',
  remove = 'remove',
  search = 'search',
  settings = 'settings',
  update = 'update',
  user = 'user',
  visibility = 'visibility',
  visibilityOff = 'visibilityOff',
}
