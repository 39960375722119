import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root',
})
export class CryptoService {
  cryptoKey = 'gkyF2wW4Iuy1Wx3tQQ08Tg390lKGDy';

  /**
   * Function to encrypt a text using CryptoJS.
   * @param {string} text - The text to encrypt.
   * @returns {string} The encrypted text.
   */
  encryptText(text: string): string {
    const key = this.cryptoKey;
    return CryptoJS.AES.encrypt(text, key).toString();
  }
  /**
   * Decrypts an encrypted text using the AES algorithm.
   * @param {string} encryptedText - The encrypted text to decrypt.
   * @returns {string} The decrypted text.
   */
  decryptText(encryptedText: string): string {
    const key = this.cryptoKey;
    const bytes = CryptoJS.AES.decrypt(encryptedText, key);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
}
