import { createReducer, on } from '@ngrx/store';
import {
  FormsState,
  initialFormsState,
  initialProductForm,
} from '@app/core/store/forms/forms.model';
import {
  initProductForm,
  saveProductForm,
  saveProductFormFailed,
  saveProductFormSuccess,
  selectProductToEdit,
  validCas,
  validCasFailed,
  validCasSuccess,
} from '@app/core/store/forms/forms.action';
import { buildTimeZone, EApiStatus } from '@app/utils/data/common.protocol';

export const formsReducer = createReducer(
  initialFormsState(),
  on(initProductForm, (state) => ({
    ...state,
    product: initialProductForm(),
  })),
  on(
    selectProductToEdit,
    (state: FormsState, { product }): FormsState => ({
      ...state,
      product: {
        ...state.product,
        apiStatus: EApiStatus.default,
        info: {
          ...state.product?.info,
          ...product,
          ZonaHoraria: buildTimeZone(),
        },
      },
    }),
  ),
  on(
    saveProductForm,
    (state: FormsState): FormsState => ({
      ...state,
      product: {
        ...state.product,
        apiStatus: EApiStatus.loading,
      },
    }),
  ),
  on(saveProductFormSuccess, (state: FormsState) => ({
    ...state,
    product: {
      ...state.product,
      apiStatus: EApiStatus.success,
    },
  })),
  on(saveProductFormFailed, (state: FormsState) => ({
    ...state,
    product: {
      ...state.product,
      apiStatus: EApiStatus.failed,
    },
  })),
  on(
    validCas,
    (state: FormsState): FormsState => ({
      ...state,
      product: {
        ...state.product,
        isValidatingCas: EApiStatus.loading,
      },
    }),
  ),
  on(
    validCasSuccess,
    (state: FormsState, { isValid }): FormsState => ({
      ...state,
      product: {
        ...state.product,
        isValidatingCas: EApiStatus.success,
        isValidCas: isValid,
      },
    }),
  ),
  on(
    validCasFailed,
    (state: FormsState): FormsState => ({
      ...state,
      product: {
        ...state.product,
        isValidatingCas: EApiStatus.failed,
      },
    }),
  ),
);
