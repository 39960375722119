export type AlertType = 'success' | 'error' | 'warning' | 'disabled';
export type AlertPosition = 'top' | 'bottom';

export enum EAlertType {
  success = 'success',
  error = 'error',
  warning = 'warning',
  disabled = 'disabled',
}

export enum EAlertPosition {
  top = 'top',
  bottom = 'bottom',
}
