import { createSelector } from '@ngrx/store';
import { selectSettingsState } from '@app/core/core.state';
import { AppSettings, SettingsState } from '@app/core/settings/settings.model';

export const selectSettings = createSelector(selectSettingsState, (state: SettingsState) => state);
export const selectAppSettings = createSelector(
  selectSettings,
  (state: SettingsState) => state.appSettings,
);
export const selectInitializationComplete = createSelector(
  selectSettings,
  (state: SettingsState) => state.initializationComplete,
);
export const selectContactUsEmail = createSelector(
  selectAppSettings,
  (state: AppSettings): string => state.contactUsEmail,
);
