import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { Store, StoreModule } from '@ngrx/store';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { metaReducers, reducers } from './core.state';
import { environment } from '@environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { HttpAuthInterceptor } from '@app/core/interceptors/http-auth.interceptor';
import { NgIdleModule } from '@ng-idle/core';
import { AuthEffects } from '@app-m-auth/store/auth.effects';
import { ServiceWorkerModule } from '@angular/service-worker';
import { appInitializer } from '@app/core/initializer/app.initializer';
import { AuthService } from '@app-m-auth/store/auth.service';
import { LocalStorageService } from '@app/core/storage/local-storage.service';
import { AppSettingsService } from '@app/core/initializer/app-settings.service';
import { AppErrorHandler } from '@app/core/interceptors/app-error-handler.service';
import { CatalogsEffects } from '@app/core/store/catalogs/catalogs.effects';
import { SignalRService } from '@app/services/signalR/signal-r.service';
import { CustomSerializer } from '@app/utils/data/custom-serializer';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';

@NgModule({
  declarations: [],
  imports: [
    // angular
    CommonModule,
    HttpClientModule,
    FormsModule,
    NgIdleModule.forRoot(),
    // ngrx
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreRouterConnectingModule.forRoot(),
    LoggerModule.forRoot({
      level: environment.production ? NgxLoggerLevel.OFF : NgxLoggerLevel.DEBUG,
      enableSourceMaps: true,
      colorScheme: ['purple', 'teal', 'gray', 'gray', 'red', 'red', 'red'],
    }),
    EffectsModule.forRoot([AuthEffects, CatalogsEffects]),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          name: 'Promsy',
          maxAge: 25,
          logOnly: environment.production,
        }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: ErrorHandler, useClass: AppErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService, LocalStorageService, AppSettingsService, Store, SignalRService],
    },
    { provide: RouterStateSerializer, useClass: CustomSerializer },
  ],
  exports: [],
})
export class CoreModule {}
