import { ApiStatusTypes, EApiStatus } from '@app/utils/data/common.protocol';

export interface UserProfileFormState {
  totalProductsBySupplier: number;
  totalProductsBySupplierBackup: number;
  statusApiUpdateInfo: ApiStatusTypes;
  statusApiGetInfo: ApiStatusTypes;
}

export const InitUserProfileFormState = (): UserProfileFormState => ({
  totalProductsBySupplier: 0,
  totalProductsBySupplierBackup: 0,
  statusApiUpdateInfo: EApiStatus.default,
  statusApiGetInfo: EApiStatus.default,
});
