import { isString } from 'lodash-es';
import { BuildDialogConfig } from '@app-m-shared/models/org-modal.model';

export const RESPONSIVE_MENU_WIDTH_LIMIT = 1920;

export const AUTH_FEATURE_KEY = 'auth';
export const SETTINGS_KEY = 'SETTINGS';

export const APP_PREFIX = 'P_NET_';
export const AUTH_KEY = 'AUTH';
export const ROUTER_FEATURE_KEY = 'router';
export const SETTINGS_FEATURE_KEY = 'settings';
export const UTILS_FEATURE_KEY = 'utils';

export const CORE_KEY = 'core';
export const ROUTER_KEY = 'router';
export const DASHBOARD_KEY = 'dashboard';
export const CATALOGS_KEY = 'catalogs';
export const FORMS_KEY = 'forms';
export const USER_PROFILE_KEY = 'userProfile';
export const PRODUCTS_KEY = 'products';

export const APP_ALERTS = 'appAlerts';

export const ROUTER_PRODUCT_DETAILS_KEY = 'productDetails';
export const DEFAULT_TIME_DEBOUNCE_SEARCH = 500;

export const DEFAULT_UUID = '00000000-0000-0000-0000-000000000000';

export enum AppViewTypes {
  Base = 'Base',
  Sm = 'Sm',
  Lg = 'Lg',
}
export const extractID = (value: any) => {
  if (isString(value)) {
    return value.replace(/['"]+/g, '');
  } else {
    return value;
  }
};
export const buildDialogConfig = (data?: any): BuildDialogConfig => {
  return {
    backdropClass: 'mat-dialog-background',
    data,
    panelClass: 'mat-dialog-style',
    disableClose: true,
    autoFocus: false,
  };
};
export const buildStringNameForAction = (type: string, description: string): string => {
  return `[${type}] ${description}`;
};

export const monthsOfYear = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const DEFAULT_ELEMENTS_BY_PAGE = 24;

/**
 * Method that processes the input string to build a suitable image name.
 * Example: "Quotation Quoter" -> "QuotationQuoter"
 *
 * @param {string} nameImage - The input string to process.
 * @returns {string} The processed image name.
 */
export const buildImageName = (nameImage: string): string => {
  return nameImage
    ?.trim() /** Remove leading and trailing white spaces from the string */
    .normalize('NFD') /** Apply canonical normalization to handle diacritics */
    .split(' ') /** Find white spaces within the string */
    .join('_') /** Replace white spaces within the string with underscores */
    .split('.') /** Find periods within the string */
    .join(
      '_',
    ) /** Replace periods within the string with underscores (to avoid creating a file extension) */
    .replace(
      /[\u0300-\u036f]/g,
      '',
    ) /** Replace accented vowels with their non-accented counterparts */
    .toLowerCase(); /** Convert the string to lowercase */
};

/**
 * @method removePercentageCharFromUrlParam
 * @description This function was created to remove percentage character from url
 * @example U2FsdGVkX1%2525252B%2525252BV%2525252Fqgp7mKU4VsNyoPb6U%2525252F8CBFpugNkkJwhDdfkNm5qw4mNyjpr7vOmqIfXC3aIxQPwsnV6blXew%2525253D%2525253D
 * @param {string} url url
 * @return {string}Return URL with percentages removed
 */
export const removePercentageCharFromUrlParam = (url: string): string => {
  const decoded: string = decodeURIComponent(url);
  if (decoded.includes('%')) {
    return removePercentageCharFromUrlParam(decoded);
  }
  return decoded;
};

/**
 * @method getTextInitials
 * @description Method to get initials from a text
 * @param {string} text - Text to get its initials
 * @returns {string} - Initials
 */
export const getTextInitials = (text: string): string => {
  if (text === 'N/A') {
    return '';
  }
  const words: string[] = text.trim().toUpperCase().split(' ');
  let initials = '';
  if (words?.length > 1) {
    initials = words[0].charAt(0) + words[1].charAt(0);
  } else {
    initials = words[0].substring(0, 2);
  }
  return initials;
};

/**
 * @method buildTimeZone
 * @description returns user timezone
 */
export const buildTimeZone = (): string => {
  const offset = new Date().getTimezoneOffset();
  const offsetDiff = offset / -60;
  const isDecimal = offset % -60 !== 0;
  const stringOffsetDiff = `0${Math.abs(Math.floor(offsetDiff)).toString()}`.slice(-2);
  return `UTC ${offsetDiff < 0 ? '-' : '+'}${stringOffsetDiff}:${isDecimal ? 5 : 0}0`;
};

export type ApiStatusTypes = 'default' | 'loading' | 'success' | 'failed';
export enum EApiStatus {
  default = 'default',
  loading = 'loading',
  success = 'success',
  failed = 'failed',
}
export type ReusableComponentsSizes = 's' | 'm';
export enum EReusableComponentSizes {
  s = 's',
  m = 'm',
}

/**
 * @type {('disponible' | 'descontinuado' | 'backorder' | 'nocomercializable')} AvailabilityTypes
 * @description Available options:
 * - 'disponible': product available.
 * - 'descontinuado': product discontinued.
 * - 'backorder': product in backorder.
 * - 'nocomercializable': product non marketable.
 * @memberof AvailabilityTypes
 */
export type AvailabilityTypes = 'disponible' | 'descontinuado' | 'backorder' | 'nocomercializable';

/**
 * Enum representing availability product keys with corresponding values in Spanish
 * @enum {string}
 */
export enum EAvailabilityTypes {
  available = 'disponible',
  discontinued = 'descontinuado',
  backorder = 'backorder',
  notMarketable = 'nocomercializable',
  notAvailable = 'nodisponible',
}

/**
 * Enum representing availability product keys with corresponding values in English
 * @enum {string}
 */
export enum EAvailabilityTypesEN {
  available = 'available',
  discontinued = 'discontinued',
  backorder = 'backorder',
  notMarketable = 'notMarketable',
  notAvailable = 'notAvailable',
}

/**
 * @enum errorsLibrary
 * @description library lo handle server errors
 * **/
export enum errorsLibrary {
  multipleSession = 'multiple_session',
}

/**
 * @enum SignalRMessages
 * @description This are messages response to signalR session service
 * **/
export enum signalRMessages {
  connected = 'Connected',
  loginAttempt = 'login_attempt',
  closedSession = 'closed_session',
  logoutSession = 'logout_session',
  closedOthersSession = 'closed_others_session',
}

/**
 * Enum representing availability presentation types key
 * @enum {string}
 */
export enum EPresentationType {
  ampolleta = 'ampolleta',
  blister = 'blister',
  bolsadealuminio = 'bolsadealuminio',
  caja = 'caja',
  frasco = 'frasco',
  botedeplastico = 'botedeplastico',
  vial = 'Vial',
}

/**
 * Enum representing availability presentation types key in English
 * @enum {string}
 */
export enum EPresentationTypeTranslation {
  aluminiumFoilBag = 'aluminiumFoilBag',
  ampule = 'ampule',
  blister = 'blister',
  box = 'box',
  glassJar = 'glassJar',
  plasticBottle = 'plasticBottle',
  vial = 'vial',
}
