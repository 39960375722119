export const blue = {
  '50': '#d6f5ff',
  '100': '#b3edff',
  '200': '#61daff',
  '300': '#14c8ff',
  '400': '#0098c7',
  '500': '#005c77',
  '600': '#004a61',
  '700': '#003747',
  '800': '#00232e',
  '900': '#001419',
};

export const green = {
  '50': '#eafbf1',
  '200': '#aaeec5',
  '300': '#7fe6a8',
  '400': '#55dd8b',
  '500': '#2dd570',
  '600': '#22aa58',
  '700': '#198042',
  '800': '#11552c',
  '900': '#082b16',
};

export const grey = {
  '50': '#f1f1f4',
  '100': '#e0e1e6',
  '200': '#c4c5cf',
  '300': '#a5a7b6',
  '400': '#898c9f',
  '500': '#6c6f84',
  '600': '#57596b',
  '700': '#40424f',
  '800': '#2c2d35',
  '900': '#151519',
};
export const purple = {
  '50': '#f2effb',
  '100': '#e1daf6',
  '200': '#c7b9ee',
  '300': '#a995e5',
  '400': '#8b70dc',
  '500': '#6f4dd3',
  '600': '#502db8',
  '700': '#3d228b',
  '800': '#29175e',
  '900': '#140b2d',
};
export const red = {
  '50': '#feecf0',
  '100': '#fdd8e1',
  '200': '#fbb1c3',
  '300': '#f986a1',
  '400': '#f75f82',
  '500': '#f53864',
  '600': '#e40c3e',
  '700': '#bc061b',
  '800': '#750620',
  '900': '#3a0310',
};
export const white = {
  '50': '#ffffff0d',
  '100': '#ffffff1a',
  '200': '#ffffff33',
  '300': '#ffffff4d',
  '400': '#ffffff66',
  '500': '#ffffff80',
  '600': '#ffffff99',
  '700': '#ffffffcc',
  '800': '#ffffffe5',
  '900': '#ffffff',
};
export const yellow = {
  '50': '#fffcf0',
  '100': '#fff7db',
  '200': '#fff1bd',
  '300': '#ffe999',
  '400': '#ffe175',
  '500': '#ffd953',
  '600': '#ffcb0f',
  '700': '#cca000',
  '800': '#8a6c00',
  '900': '#423400',
};

export const orange = {
  '50': '#ffeeeb',
  '100': '#ffe1db',
  '200': '#ffc2b8',
  '300': '#ffa08f',
  '400': '#ff816b',
  '500': '#ff6348',
  '600': '#ff2b05',
  '700': '#c21d00',
  '800': '#851400',
  '900': '#420a00',
};

export const backgroundSkeleton = '#E4E4E4';
