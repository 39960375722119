import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtmDividerComponent } from './atm-divider.component';

@NgModule({
  declarations: [AtmDividerComponent],
  imports: [CommonModule],
  exports: [AtmDividerComponent],
})
export class AtmDividerModule {}
