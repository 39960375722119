import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  ACCEPT_TERMS,
  authLogoutLoad,
  authLogoutSuccess,
  EMPTY_ACTION,
  GET_QR_CODE_LOAD,
  GET_QR_CODE_SUCCESS,
  LOGIN_SUCCESS,
  SET_LOGIN_TOKEN,
  SET_TOKEN,
  UPDATE_TOKEN,
  VALIDATE_PIN_LOAD,
  VALIDATE_PIN_SUCCESS,
} from './auth.actions';
import {
  catchError,
  delayWhen,
  EMPTY,
  exhaustMap,
  map,
  mergeMap,
  of,
  tap,
  throwError,
  timer,
} from 'rxjs';
import { AppToken } from '@app/utils/models/auth/token.model';
import { AUTH_KEY, extractID } from '@app/utils/data/common.protocol';
import { appRoutes } from '@app/core/routes';
import { CodeStatus } from '@app/utils/models/mol-confirm-code';
import { LocalStorageService } from '@app/core/storage/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { AuthState } from '@app/utils/models/auth/auth.models';
import { SET_INITIALIZATION_COMPLETE } from '@app/core/settings/settings.actions';
import { SistemaService, SistemaUsuariosService } from '@api-promsy-services';
import { VUsuarioPromsy } from '@api-promsy-models';
import { generateMessage, LOG_FAILED } from '@app/utils/helpers/logger';
import { NGXLogger } from 'ngx-logger';
import { SignalRService } from '@app/services/signalR/signal-r.service';
import { selectCurrentRoute } from '@app-m-auth/store/router/router.selectors';

const fileName = 'auth.effects';

@Injectable()
export class AuthEffects {
  IdDialogAcceptTermAndConditions = '';

  constructor(
    private actions$: Actions,
    private localStorageService: LocalStorageService,
    private authService: AuthService,
    private router: Router,
    private store: Store,
    private dialog: MatDialog,
    private sistemaService: SistemaService,
    private sistemaUsuariosService: SistemaUsuariosService,
    private loggerService: NGXLogger,
    private signalRService: SignalRService,
  ) {}

  loginLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_LOGIN_TOKEN),
      exhaustMap(({ token }) => {
        return this.authService.userInfo(token.access_token).pipe(
          map((userInfo: any) => {
            // this.logger.debug(
            //   servicesLogger.generateMessage(
            //     FILE_NAME,
            //     servicesLogger.LOG_SUCCEEDED,
            //     'Al obtener el UserInfo',
            //   ),
            //   userInfo,
            // );
            // return {
            //   token,
            //   userInfo,
            // };
            return {
              token: token,
              userInfo: userInfo,
              codeAuthStatus: CodeStatus.default,
            };
          }),
          catchError((error) => {
            // this.logger.error(
            //   servicesLogger.generateMessage(
            //     FILE_NAME,
            //     servicesLogger.LOG_FAILED,
            //     'Al obtener el UserInfo',
            //   ),
            //   error.message,
            // );
            this.store.dispatch(authLogoutLoad({ multiSession: false }));
            return EMPTY;
          }),
        );
      }),
      exhaustMap((authData: AuthState) => {
        return this.authService
          .refreshToken(authData.token.refresh_token, authData.token.idSession)
          .pipe(
            map((token: AppToken) => {
              // this.logger.debug(
              //   servicesLogger.generateMessage(
              //     FILE_NAME,
              //     servicesLogger.LOG_SUCCEEDED,
              //     'Al actualizar el token',
              //   ),
              //   token,
              // );
              return SET_TOKEN({
                auth: {
                  ...authData,
                  token: {
                    ...token,
                    idSession: authData?.token?.idSession,
                  },
                },
                navigation: true,
              });
            }),
            catchError((error) => {
              // this.logger.error(
              //   servicesLogger.generateMessage(
              //     FILE_NAME,
              //     servicesLogger.LOG_FAILED,
              //     'Al actualizar el token',
              //   ),
              //   error.message,
              // );
              return of(authLogoutLoad({ multiSession: false }));
            }),
          );
      }),
    ),
  );

  whoAmI$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SET_TOKEN),
      concatLatestFrom(() => this.store.select(selectCurrentRoute)),
      exhaustMap(([{ auth, navigation }, currentRoute]) => {
        return this.sistemaService.HomeWhoAmI().pipe(
          map((userDetail: VUsuarioPromsy) => {
            // this.logger.debug(
            //   servicesLogger.generateMessage(
            //     FILE_NAME,
            //     servicesLogger.LOG_SUCCEEDED,
            //     'Al consultar WhoAmI',
            //   ),
            //   userDetail,
            // );

            const authState: AuthState = {
              isAuthenticated: true,
              token: auth.token,
              userInfo: { ...auth.userInfo, ...userDetail },
              codeAuthStatus: CodeStatus.default,
            };
            this.localStorageService.setItem(AUTH_KEY, authState);
            if (navigation) {
              this.router
                .navigate(['/'])
                .then(() => {
                  // this.logger.debug(
                  //   servicesLogger.generateMessage(
                  //     FILE_NAME,
                  //     servicesLogger.LOG_SUCCEEDED,
                  //     'Respondio la promesa',
                  //   ),
                  //   returnUrl,
                  // );
                  return currentRoute;
                })
                .catch(() => {
                  // this.logger.error(
                  //   servicesLogger.generateMessage(
                  //     FILE_NAME,
                  //     servicesLogger.LOG_FAILED,
                  //     'Fallo la promesa',
                  //   ),
                  //   returnUrl,
                  // );
                })
                .finally(() => {
                  // this.logger.debug(
                  //   servicesLogger.generateMessage(
                  //     FILE_NAME,
                  //     servicesLogger.LOG_SUCCEEDED,
                  //     'Finalizo la promesa',
                  //   ),
                  //   returnUrl,
                  // );
                });
            }

            return LOGIN_SUCCESS({ auth: authState, delay: true });
          }),
          catchError((error) => {
            // this.logger.error(
            //   servicesLogger.generateMessage(
            //     FILE_NAME,
            //     servicesLogger.LOG_FAILED,
            //     'Al consultar WhoAmI',
            //   ),
            //   error.message,
            // );
            return EMPTY;
          }),
        );
      }),
    ),
  );

  startRefreshToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOGIN_SUCCESS, UPDATE_TOKEN),
      // DOCS: Se refresca el token 1 minutos antes de expirar
      delayWhen(({ auth, delay }) => {
        return delay ? timer((auth.token.expires_in - 60) * 1000) : timer(0);
      }),
      // delay((300 - 120) * 1000),
      exhaustMap(({ auth, delay }) => {
        // DOCS: Cerró sesión antes de cumplirse el tiempo de actualizar el token
        const storageToken = this.localStorageService.getItem(AUTH_KEY);
        if (!storageToken?.token?.refresh_token) {
          return of(authLogoutLoad({ multiSession: false }));
        }
        // DOCS: Cambio de sesión antes de cumplirse el tiempo de actualizar el token
        if (
          storageToken.token.refresh_token &&
          storageToken.token.refresh_token !== auth.token.refresh_token
        ) {
          return EMPTY;
        }
        // TODO: DESCOMENTAR CUANDO SE IMPLEMENTE SW NUEVAMENTE
        // DOCS: SW VERIFICA SI EXISTE UNA VERSIÓN NUEVA DEL SISTEMA
        /*        this.swUpdate
                                  .checkForUpdate()
                                  .then(() => (this.updateCheckText = 'resolved'))
                                  .catch((err) => (this.updateCheckText = `rejected: ${err.message}`));*/
        return this.authService.refreshToken(auth.token.refresh_token, auth.token.idSession).pipe(
          map((token: AppToken) => {
            // this.logger.debug(
            //   servicesLogger.generateMessage(
            //     FILE_NAME,
            //     servicesLogger.LOG_SUCCEEDED,
            //     'Al actualizar el token',
            //   ),
            //   token,
            // );
            this.localStorageService.updateItem(
              AUTH_KEY,
              {
                ...token,
                idSession: auth?.token?.idSession,
              },
              'token',
            );
            if (!delay) {
              this.store.dispatch(
                SET_TOKEN({
                  auth: {
                    ...auth,
                    token: {
                      ...token,
                      idSession: auth?.token?.idSession,
                    },
                  },
                }),
              );
              this.store.dispatch(SET_INITIALIZATION_COMPLETE());
            }
            return UPDATE_TOKEN({
              auth: {
                ...auth,
                token: {
                  ...token,
                  idSession: auth?.token?.idSession,
                },
              },
              delay: true,
            });
          }),
        );
      }),
      catchError((error) => {
        // this.logger.error(
        //   servicesLogger.generateMessage(
        //     FILE_NAME,
        //     servicesLogger.LOG_FAILED,
        //     'Al actualizar el token',
        //   ),
        //   error,
        // );
        this.killSessionData(true);
        return EMPTY;
      }),
    ),
  );

  logOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authLogoutLoad),
        mergeMap(({ multiSession }) => {
          this.killSessionData();
          if (!multiSession) {
            this.signalRService.closeSession();
          }
          this.store.dispatch(authLogoutSuccess());
          return EMPTY;
        }),
      ),
    { dispatch: false },
  );

  acceptTerms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ACCEPT_TERMS),
      mergeMap(({ user, IdDialogClose }) => {
        return this.sistemaUsuariosService
          .UsuarioGuardarOActualizar({
            usuario: {
              ...user,
            },
          })
          .pipe(
            map(() => {
              const auth: AuthState = this.localStorageService.getItem(AUTH_KEY);

              this.dialog.getDialogById(IdDialogClose)?.close();
              if (auth) {
                this.router.navigate(['/']);
                this.store.dispatch(SET_TOKEN({ auth, navigation: true }));
              }
              return EMPTY_ACTION();
            }),
            catchError((error) => {
              this.loggerService.error(
                generateMessage(fileName, LOG_FAILED, 'Error in acceptTerms$'),
                error,
              );
              return of(EMPTY_ACTION());
            }),
          );
      }),
    );
  });

  /** 2FA Effects **/
  getQrCodeImage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(GET_QR_CODE_LOAD),
      mergeMap(() => {
        return this.sistemaService.HomeGetQRCodeImage().pipe(
          map((response) => {
            return GET_QR_CODE_SUCCESS({ qrCodeImage: extractID(response) });
          }),
          catchError((error) => {
            this.loggerService.error(
              generateMessage(fileName, LOG_FAILED, 'Error in getQrCodeImage$'),
              error,
            );
            return of(EMPTY_ACTION());
          }),
        );
      }),
    );
  });

  validatePin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(VALIDATE_PIN_LOAD),
      mergeMap(({ pin }) => {
        return this.sistemaService.HomeValidatePin({ pin }).pipe(
          map((response) => {
            const auth: AuthState = this.localStorageService.getItem(AUTH_KEY);

            if (auth) {
              this.store.dispatch(SET_TOKEN({ auth: auth, navigation: true }));
            }
            return VALIDATE_PIN_SUCCESS({ value: response });
          }),
          catchError((error) => {
            this.loggerService.error(
              generateMessage(fileName, LOG_FAILED, 'Error in validatePin$'),
              error,
            );
            return of(EMPTY_ACTION());
          }),
        );
      }),
    );
  });

  killSessionData(refreshTokenError = false): void {
    this.localStorageService.removeItem(AUTH_KEY);
    if (refreshTokenError) {
      location.reload();
    } else {
      this.router.navigate(['/']);
    }
  }
}
