import { Component, Input } from '@angular/core';
import { FontWeight } from '@app/utils/models/font-weight';
import { FontColor } from '@app/utils/models/e-font-color';
import { CursorType } from '@app/utils/models/cursor-type';
import { TextDecoration } from '@app/utils/models/reactive-forms/e-text-decoration';
import { TextLine } from '@app/utils/models/max-lines';

/**
 * Component representing a text h6 with font weight options.
 */
@Component({
  selector: 'atm-text-h6',
  templateUrl: './atm-text-h6.component.html',
  styleUrls: ['./atm-text-h6.component.scss', '../../../../../styles/_text-configurations.scss'],
})
/**
 * @property {FontWeight} fontWeight Defines the font weight of the text. Can be 'regular', 'medium' or 'bold'.
 * @property {FontColor} fontColor Defines the color of the text.
 * @property {CursorType} cursorType Defines the type of cursor to be displayed when hovering over the text.
 * @property {TextDecoration} textDecoration Defines the decoration applied to the text, such as underline or none.
 * @property {TextLine} textLine Defines the limit lines applied to the text.
 */
export class AtmTextH6Component {
  /**
   * Defines the font weight of the text. Can be 'regular', 'medium' or 'bold'
   */
  @Input() fontWeight: FontWeight = 'regular';

  /**
   * Defines the color of the text.
   */
  @Input() fontColor: FontColor = '';

  /**
   * Defines the type of cursor to be displayed when hovering over the text.
   */
  @Input() cursorType: CursorType = '';

  /**
   * Defines the decoration applied to the text, such as underline or none.
   */
  @Input() textDecoration: TextDecoration = 'none';

  /**
   * Defines the limit lines applied to the text.
   */
  @Input() textLine: TextLine = 'none';
}
