import {
  QueryResultCatDisponibilidadObj,
  QueryResultCatTipoPresentacionObj,
  QueryResultCatUnidadObj,
} from '@api-promsy-models';
import { ApiStatusTypes } from '@app/utils/data/common.protocol';

export interface CatalogsState {
  catUnit: CatUnit;
  catPresentationType: CatPresentationType;
  catAvailability: CatAvailability;
}

export interface CatalogStatus {
  apiStatus?: ApiStatusTypes;
  needsToReload: boolean;
}

export interface CatUnit extends CatalogStatus {
  data: QueryResultCatUnidadObj;
}

export interface CatPresentationType extends CatalogStatus {
  data: QueryResultCatTipoPresentacionObj;
}
export interface CatAvailability extends CatalogStatus {
  data: QueryResultCatDisponibilidadObj;
}

export const initialCatalogsState = (): CatalogsState => ({
  catUnit: {
    data: {} as QueryResultCatUnidadObj,
    needsToReload: true,
  },
  catPresentationType: {
    data: {} as QueryResultCatTipoPresentacionObj,
    needsToReload: true,
  },
  catAvailability: {
    data: {} as QueryResultCatDisponibilidadObj,
    needsToReload: true,
  },
});
