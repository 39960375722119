import { selectAuthState } from '@app/core/core.state';
import { AuthState } from '@app/utils/models/auth/auth.models';
import { CanMatchFn, Router, UrlTree } from '@angular/router';
import { appRoutes } from '@app/core/routes';
import { inject, Injectable } from '@angular/core';
import { lastValueFrom, take } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { VUsuarioPromsy } from '@api-promsy-models';
import { selectWhoAmiInfo } from '@app-m-auth/store/auth.selector';

@Injectable({
  providedIn: 'root',
})
export class SystemGuard {
  async canMatch(store: Store, router: Router): Promise<boolean | UrlTree> {
    const auth: AuthState = await lastValueFrom(store.pipe(select(selectAuthState), take(1)));
    const whoAmI: VUsuarioPromsy = await lastValueFrom(
      store.pipe(select(selectWhoAmiInfo), take(1)),
    );
    /** If is not authenticated will be return to login page **/
    if (!auth.isAuthenticated) {
      return router.createUrlTree([appRoutes.auth, appRoutes.login]);
    }
    /** If is loged buth is not authenticated with 2fa will ve redirect to 2fa page **/
    if (whoAmI.SolicitarPINdeValidacion) {
      return router.createUrlTree([appRoutes.auth, appRoutes.twoFactorAuth]);
    }

    /** If is loged buth is not accepted termns and conditions **/
    if (whoAmI && !whoAmI.AceptaTerminos) {
      return router.createUrlTree([appRoutes.auth, appRoutes.termsAndConditions]);
    }

    /** If is autenticated in login and 2fa will be continue navigate **/
    return true;
  }
}

export const systemGuard: CanMatchFn = () =>
  inject(SystemGuard).canMatch(inject(Store), inject(Router));
