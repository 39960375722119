/**
 * Represents the possible cursor types.
 * @typedef {'' | 'auto' | 'default' | 'pointer' | 'crosshair' | 'move' | 'text' | 'wait' | 'help' | 'progress' | 'not-allowed'} CursorType
 */

export type CursorType =
  | ''
  | 'auto'
  | 'default'
  | 'pointer'
  | 'crosshair'
  | 'move'
  | 'text'
  | 'wait'
  | 'help'
  | 'progress'
  | 'not-allowed';

/**
 * Enum representing different cursor types.
 * @readonly
 * @enum {string}
 */
export enum cursorType {
  /**
   * Automatically determined cursor.
   */
  auto = 'auto',

  /**
   * Default cursor.
   */
  default = 'default',

  /**
   * Pointer cursor.
   */
  pointer = 'pointer',

  /**
   * Crosshair cursor.
   */
  crosshair = 'crosshair',

  /**
   * Move cursor.
   */
  move = 'move',

  /**
   * Text input cursor.
   */
  text = 'text',

  /**
   * Wait cursor.
   */
  wait = 'wait',

  /**
   * Help cursor.
   */
  help = 'help',

  /**
   * Progress cursor.
   */
  progress = 'progress',

  /**
   * Not allowed cursor.
   */
  notAllowed = 'not-allowed',
}
