/**
 * Represents the possible font weights.
 * @typedef {'regular' | 'medium' | 'bold' | 'semiBold'} FontWeight
 */
export type FontWeight = 'regular' | 'medium' | 'bold' | 'semiBold' | '';

/**
 * Enum representing different font weights.
 * @readonly
 * @enum {FontWeight}
 */
export enum fontWeight {
  /**
   * Regular font weight.
   */
  regular = 'regular',

  /**
   * Medium font weight.
   */
  medium = 'medium',

  /**
   * Bold font weight.
   */
  bold = 'bold',
  /**
   * Semi Bold font weight.
   */
  semiBold = 'semiBold',
}
