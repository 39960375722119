import { createSelector } from '@ngrx/store';
import { selectFormsState } from '@app/core/core.state';
import { FormsState } from '@app/core/store/forms/forms.model';
import { ProductoPromsy } from '@api-promsy-models';
import { ApiStatusTypes, DEFAULT_UUID, EApiStatus } from '@app/utils/data/common.protocol';
import { ValidationErrors } from '@angular/forms';

export const selectProductForm = createSelector(
  selectFormsState,
  (state: FormsState): ProductoPromsy => state.product.info,
);
export const selectProductFormApiStatus = createSelector(
  selectFormsState,
  (state: FormsState): ApiStatusTypes => state.product.apiStatus,
);
export const selectIsNewProduct = createSelector(
  selectProductForm,
  (state: ProductoPromsy): boolean => state.IdProducto === DEFAULT_UUID,
);
export const selectIsValidatingCas = createSelector(
  selectFormsState,
  (state: FormsState): EApiStatus | undefined => state.product.isValidatingCas,
);
export const selectIsValidCas = createSelector(
  selectFormsState,
  (state: FormsState): boolean | undefined => state.product.isValidCas,
);
export const selectCasFormValidation = createSelector(
  selectIsValidCas,
  (isValid: boolean | undefined): boolean | undefined => isValid,
);
