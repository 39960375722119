import { createAction, props } from '@ngrx/store';
import { AppToken } from '@app/utils/models/auth/token.model';
import { AuthState } from '@app/utils/models/auth/auth.models';
import { CodeStatus } from '@app/utils/models/mol-confirm-code';
import { buildStringNameForAction } from '@app/utils/data/common.protocol';
import { VUsuarioPromsy } from '@api-promsy-models';

const fileName = 'Auth';
// export const LOGIN_LOAD = createAction('[Auth] Login Load', props<{user: IUser}>());
export const SET_LOGIN_TOKEN = createAction(
  buildStringNameForAction(fileName, 'Set login token user'),
  props<{ token: AppToken }>(),
);
export const LOGIN_SUCCESS = createAction(
  buildStringNameForAction(fileName, 'Login'),
  props<{ auth: AuthState; delay: boolean }>(),
);

export const LOGIN_FAILED = createAction(buildStringNameForAction(fileName, 'Login Failed'));
export const authLogoutSuccess = createAction(
  buildStringNameForAction(fileName, 'Auth Logout success'),
);
export const authLogoutLoad = createAction(
  buildStringNameForAction(fileName, 'Auth Logout load'),
  props<{ multiSession: boolean }>(),
);
export const ACCEPT_TERMS = createAction(
  buildStringNameForAction(fileName, 'Acept terms'),
  props<{ user: VUsuarioPromsy; IdDialogClose: string }>(),
);
export const SET_TOKEN = createAction(
  buildStringNameForAction(fileName, 'Set token'),
  props<{
    auth: AuthState;
    navigation?: boolean;
  }>(),
);
export const UPDATE_TOKEN = createAction(
  buildStringNameForAction(fileName, 'Update token'),
  props<{ auth: AuthState; delay: boolean }>(),
);
export const SET_RETURN_URL = createAction(
  buildStringNameForAction(fileName, 'Set Return url'),
  props<{
    returnUrl: string;
  }>(),
);
export const START_REFRESH_TOKEN = createAction(
  buildStringNameForAction(fileName, 'Start refresh token'),
);
export const EMPTY_ACTION = createAction(buildStringNameForAction(fileName, 'Empty action'));
export const GET_QR_CODE_LOAD = createAction(
  buildStringNameForAction(fileName, 'Get Qr code load'),
);
export const GET_QR_CODE_SUCCESS = createAction(
  buildStringNameForAction(fileName, 'Get Qr code success'),
  props<{ qrCodeImage: string }>(),
);
export const VALIDATE_PIN_LOAD = createAction(
  buildStringNameForAction(fileName, 'Validate pin load'),
  props<{
    pin: string;
  }>(),
);
export const VALIDATE_PIN_SUCCESS = createAction(
  buildStringNameForAction(fileName, 'Validate pin success'),
  props<{ value: boolean }>(),
);
export const UPDATE_CODE_STATUS = createAction(
  buildStringNameForAction(fileName, 'Update code status'),
  props<{ status: CodeStatus }>(),
);
export const CLEAR_LOGIN_STATE = createAction(
  buildStringNameForAction(fileName, 'Clear login state'),
);
export const getInfoWhoAmiSuccess = createAction(
  buildStringNameForAction(fileName, 'Get Info WhoAmi Success'),
  props<{ response: VUsuarioPromsy }>(),
);
export const setIdConnectionHub = createAction(
  buildStringNameForAction(fileName, 'Set id connection hub'),
  props<{ idConnectionHub: string | undefined }>(),
);
