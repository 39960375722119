export enum SERVER_URLS {
  /** Dev */

  apiDev = 'https://172.24.32.35',
  apiPortDev = 0,
  identityDev = 'https://172.24.32.35',
  identityPortDev = 9002,

  /** Qa */
  apiQa = 'https://172.24.32.32',
  apiPortQa = 442,
  identityQa = 'https://172.24.32.32',
  identityPortQa = 9002,

  /** Uat */
  apiUat = 'https://172.24.32.32',
  apiPortUat = 446,
  identityUat = 'https://172.24.32.32',
  identityPortUat = 9004,

  /** Prod */

  apiProd = 'https://172.24.20.21',
  apiPortProd = 0,
  identityProd = 'https://172.24.20.21',
  identityPortProd = 9000,
}
