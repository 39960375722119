import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtmSkeletonComponent } from './atm-skeleton.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

@NgModule({
  declarations: [AtmSkeletonComponent],
  imports: [CommonModule, NgxSkeletonLoaderModule],
  exports: [AtmSkeletonComponent],
})
export class AtmSkeletonModule {}
