import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtmTextCaptionComponent } from './atm-text-caption.component';

@NgModule({
  declarations: [AtmTextCaptionComponent],
  imports: [CommonModule],
  exports: [AtmTextCaptionComponent],
})
export class AtmTextCaptionModule {}
