/**
 * Type representing the different available font colors.
 */
export type FontColor =
  | ''
  | 'accentBlue'
  | 'accentGreen'
  | 'accentGrey'
  | 'accentPurple'
  | 'accentRed'
  | 'accentYellow'
  | 'buttonLink'
  | 'buttonLinkHover'
  | 'buttonNormal'
  | 'buttonPrimaryDisabled'
  | 'buttonSecondaryDisable'
  | 'buttonSecondaryNormal'
  | 'default'
  | 'disable'
  | 'error'
  | 'link'
  | 'linkHover'
  | 'primary'
  | 'secondary'
  | 'secondaryDisable'
  | 'statNegative'
  | 'statPositive'
  | 'statWarning'
  | string
  | 'tertiary';

/**
 * Enumeration mapping font colors to their corresponding CSS values.
 */
export enum EFontColor {
  '' = '',
  accentBlue = 'accentBlue',
  accentGreen = 'accentGreen',
  accentGrey = 'accentGrey',
  accentOrange = 'accentOrange',
  accentPurple = 'accentPurple',
  accentRed = 'accentRed',
  accentYellow = 'accentYellow',
  buttonLink = 'buttonLink',
  buttonLinkHover = 'buttonLinkHover',
  buttonNormal = 'buttonNormal',
  buttonPrimaryDisabled = 'buttonPrimaryDisabled',
  buttonSecondaryDisable = 'buttonSecondaryDisable',
  buttonSecondaryNormal = 'buttonSecondaryNormal',
  default = 'default',
  disable = 'disable',
  error = 'error',
  link = 'link',
  linkHover = 'linkHover',
  primary = 'primary',
  secondary = 'secondary',
  secondaryDisable = 'secondaryDisable',
  statNegative = 'statNegative',
  statPositive = 'statPositive',
  statWarning = 'statWarning',
  tertiary = 'tertiary',
}
