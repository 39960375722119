import { createAction, props } from '@ngrx/store';
import { buildStringNameForAction } from '@app/utils/data/common.protocol';
import { AlertConfigState } from '@app/core/store/alerts/alert.model';

const fileName = 'Alerts';
export const updateAlertStatus = createAction(
  buildStringNameForAction(fileName, 'Update Alert Status'),
  props<{ data: AlertConfigState }>(),
);
export const resetAlertStatus = createAction(
  buildStringNameForAction(fileName, 'Reset Alert Status'),
);
export const linkButtonAction = createAction(
  buildStringNameForAction(fileName, 'Link button action'),
  props<{ kay: string }>(),
);
