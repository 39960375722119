import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import {
  AUTH_FEATURE_KEY,
  DASHBOARD_KEY,
  ROUTER_FEATURE_KEY,
  ROUTER_PRODUCT_DETAILS_KEY,
  SETTINGS_FEATURE_KEY,
  UTILS_FEATURE_KEY,
  CATALOGS_KEY,
  USER_PROFILE_KEY,
  PRODUCTS_KEY,
  APP_ALERTS,
  FORMS_KEY,
} from '../utils/data/common.protocol';
import { AuthState } from '../utils/models/auth/auth.models';
import { authReducer } from '@app-m-auth/store/auth.reducer';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { RouterStateUrl } from '../utils/data/router.state';
import { initStateFromLocalStorage } from '@app/core/storage/local-storage.reducer';
import { DashboardState } from '@app-m-dashboard/store/dashboard.models';
import { SettingsState } from '@app/core/settings/settings.model';
import { settingsReducer } from '@app/core/settings/settings.reducer';
import { UtilsState } from '@app/core/utils/utils.model';
import { utilsReducer } from '@app/core/utils/utils.reducer';
import { IProductsState } from '@app-m-products/store/products.models';
import { ProductDetailsState } from '@app/utils/models/product-details/product-details.model';
import { CatalogsState } from '@app/utils/models/catalogs/catalogs.model';
import { userProfileReducer } from '@app/modules/core-container/user-profile/user-profile.reducer';
import { UserProfileFormState } from '@app/modules/core-container/user-profile/user-profile.models';
import { catalogsReducer } from '@app/core/store/catalogs/catalogs.reducer';
import { AlertConfigState } from '@app/core/store/alerts/alert.model';
import { alertsReducer } from '@app/core/store/alerts/alerts.reducer';
import { FormsState } from '@app/core/store/forms/forms.model';
import { formsReducer } from '@app/core/store/forms/forms.reducer';

export const metaReducers: MetaReducer<AppState>[] = [initStateFromLocalStorage];

export interface AppState {
  [AUTH_FEATURE_KEY]: AuthState;
  [CATALOGS_KEY]: CatalogsState;
  [FORMS_KEY]: FormsState;
  [ROUTER_FEATURE_KEY]: RouterReducerState<RouterStateUrl>;
  [SETTINGS_FEATURE_KEY]: SettingsState;
  [UTILS_FEATURE_KEY]: UtilsState;
  [USER_PROFILE_KEY]: UserProfileFormState;
  [APP_ALERTS]: AlertConfigState;
}
export const reducers: ActionReducerMap<AppState> = {
  [AUTH_FEATURE_KEY]: authReducer,
  [CATALOGS_KEY]: catalogsReducer,
  [FORMS_KEY]: formsReducer,
  [ROUTER_FEATURE_KEY]: routerReducer,
  [SETTINGS_FEATURE_KEY]: settingsReducer,
  [UTILS_FEATURE_KEY]: utilsReducer,
  [USER_PROFILE_KEY]: userProfileReducer,
  [APP_ALERTS]: alertsReducer,
};
export const selectRouterState =
  createFeatureSelector<RouterReducerState<RouterStateUrl>>(ROUTER_FEATURE_KEY);

export const selectAuthState = createFeatureSelector<AuthState>(AUTH_FEATURE_KEY);
export const selectCatalogsState = createFeatureSelector<CatalogsState>(CATALOGS_KEY);
export const selectFormsState = createFeatureSelector<FormsState>(FORMS_KEY);
export const selectDashboardState = createFeatureSelector<DashboardState>(DASHBOARD_KEY);
export const selectProductState = createFeatureSelector<IProductsState>(PRODUCTS_KEY);

export const selectSettingsState = createFeatureSelector<SettingsState>(SETTINGS_FEATURE_KEY);
export const selectUtilsState = createFeatureSelector<UtilsState>(UTILS_FEATURE_KEY);

export const selectProductDetailsStateFeature = createFeatureSelector<ProductDetailsState>(
  ROUTER_PRODUCT_DETAILS_KEY,
);
export const selectUserProfileState = createFeatureSelector<UserProfileFormState>(USER_PROFILE_KEY);
export const selectAlertConfig = createFeatureSelector<AlertConfigState>(APP_ALERTS);
