import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtmImageComponent } from './atm-image.component';
import { AtmSkeletonModule } from '@app-atoms/atm-skeleton/atm-skeleton.module';

@NgModule({
  declarations: [AtmImageComponent],
  exports: [AtmImageComponent],
  imports: [CommonModule, AtmSkeletonModule],
})
export class AtmImageModule {}
