/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiPromsyConfiguration as __Configuration } from '../api-promsy-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GMDashboardBestSeller } from '../models/gmdashboard-best-seller';
import { GMDashboardData } from '../models/gmdashboard-data';
import { GMDashboardBusinessConversionData } from '../models/gmdashboard-business-conversion-data';
@Injectable({
  providedIn: 'root',
})
class DashboardService extends __BaseService {
  static readonly DashboardGetBestSellerPath = '/GetBestSeller';
  static readonly DashboardGetDataBrandHealthAndProductsDashboardPath = '/GetDataBrandHealthAndProductsDashboard';
  static readonly DashboardGetDataBusinessConversionPath = '/GetDataBusinessConversion';
  static readonly DashboardGetTotalProductsPath = '/GetTotalProducts';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * GetBestSeller Dashboard
   * @return OK
   */
  DashboardGetBestSellerResponse(): __Observable<__StrictHttpResponse<Array<GMDashboardBestSeller>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/GetBestSeller`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<GMDashboardBestSeller>>;
      })
    );
  }
  /**
   * GetBestSeller Dashboard
   * @return OK
   */
  DashboardGetBestSeller(): __Observable<Array<GMDashboardBestSeller>> {
    return this.DashboardGetBestSellerResponse().pipe(
      __map(_r => _r.body as Array<GMDashboardBestSeller>)
    );
  }

  /**
   * GetDataBrandHealthAndProductsDashboard Dashboard
   * @return OK
   */
  DashboardGetDataBrandHealthAndProductsDashboardResponse(): __Observable<__StrictHttpResponse<GMDashboardData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/GetDataBrandHealthAndProductsDashboard`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GMDashboardData>;
      })
    );
  }
  /**
   * GetDataBrandHealthAndProductsDashboard Dashboard
   * @return OK
   */
  DashboardGetDataBrandHealthAndProductsDashboard(): __Observable<GMDashboardData> {
    return this.DashboardGetDataBrandHealthAndProductsDashboardResponse().pipe(
      __map(_r => _r.body as GMDashboardData)
    );
  }

  /**
   * GetDataBusinessConversion Dashboard
   * @return OK
   */
  DashboardGetDataBusinessConversionResponse(): __Observable<__StrictHttpResponse<GMDashboardBusinessConversionData>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/GetDataBusinessConversion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GMDashboardBusinessConversionData>;
      })
    );
  }
  /**
   * GetDataBusinessConversion Dashboard
   * @return OK
   */
  DashboardGetDataBusinessConversion(): __Observable<GMDashboardBusinessConversionData> {
    return this.DashboardGetDataBusinessConversionResponse().pipe(
      __map(_r => _r.body as GMDashboardBusinessConversionData)
    );
  }

  /**
   * GetTotalProducts Dashboard
   * @return OK
   */
  DashboardGetTotalProductsResponse(): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/GetTotalProducts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: parseFloat((_r as HttpResponse<any>).body as string) }) as __StrictHttpResponse<number>
      })
    );
  }
  /**
   * GetTotalProducts Dashboard
   * @return OK
   */
  DashboardGetTotalProducts(): __Observable<number> {
    return this.DashboardGetTotalProductsResponse().pipe(
      __map(_r => _r.body as number)
    );
  }
}

module DashboardService {
}

export { DashboardService }
