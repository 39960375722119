import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrgAlertComponent } from './org-alert.component';
import { AtmIconModule } from '@app-atoms/atm-icon/atm-icon.module';
import { AtmTextUiModule } from '@app-atoms/atm-text-ui/atm-text-ui.module';
import { MolButtonModule } from '@app-m-shared/molecules/mol-button/mol-button.module';
import { MolButtonLinkModule } from '@app-molecules/mol-button-link/mol-button-link.module';

@NgModule({
  declarations: [OrgAlertComponent],
  exports: [OrgAlertComponent],
  imports: [CommonModule, AtmIconModule, AtmTextUiModule, MolButtonModule, MolButtonLinkModule],
})
export class OrgAlertModule {}
