import { createReducer, on } from '@ngrx/store';
import { InitialAlertConfigState } from '@app/core/store/alerts/alert.model';
import { resetAlertStatus, updateAlertStatus } from '@app/core/store/alerts/alerts.action';

export const alertsReducer = createReducer(
  InitialAlertConfigState(),
  on(updateAlertStatus, (state, { data }) => ({
    ...state,
    ...data,
  })),
  on(resetAlertStatus, (state) => ({
    ...InitialAlertConfigState(),
  })),
);
