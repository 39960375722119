import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import * as signalR from '@microsoft/signalr';
import { HttpTransportType, HubConnection } from '@microsoft/signalr';
import { select, Store } from '@ngrx/store';
import { AppState } from '@app/core/core.state';
import { authLogoutLoad } from '@app-m-auth/store/auth.actions';
import { lastValueFrom, take } from 'rxjs';
import { selectIdSession } from '@app-m-auth/store/auth.selector';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { updateAlertStatus } from '@app/core/store/alerts/alerts.action';
import { AUTH_KEY, signalRMessages } from '@app/utils/data/common.protocol';
import { LocalStorageService } from '@app/core/storage/local-storage.service';
import { MultiSessionModalComponent } from '@app/modules/core-container/multi-session-modal/multi-session-modal.component';
import { AuthState } from '@app/utils/models/auth/auth.models';

@Injectable({
  providedIn: 'root',
})
/**
 * @property connection {Connection} - Is a socket to connect with server, you need URL for connection.
 * @property proquifaNetHubProxy {Proxy} - Name of the hub from which events are listened to and emitted.
 * **/
export class SignalRService {
  /** connection to server **/
  connection: HubConnection;

  constructor(
    private store: Store<AppState>,
    private dialog: MatDialog,
    private localStorageService: LocalStorageService,
  ) {
    // DOCS: Esta petición no pasa por el interceptor http
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(
        `${environment.identityServerUrl}:${environment.identityPort}${environment.identityBaseHref}/SessionHub`,
        {
          transport: HttpTransportType.ServerSentEvents,
        },
      )
      .withAutomaticReconnect()
      .build();

    this.connection.on('NotificationMultipleSession', (messaje, idConnectionHub) => {
      const auth: AuthState = this.localStorageService.getItem(AUTH_KEY);
      const currentSession = auth.token.idSession;
      /**
       * MultiSession modal configuration
       * **/
      const config: MatDialogConfig = {
        disableClose: true,
        width: '651px',
        panelClass: 'content-dialog',
        backdropClass: 'backdrop-dialog',
        data: { newSessionAlert: messaje == 'login_attempt' },
      };
      /** If is login attempt and current session is logged **/
      if (messaje == signalRMessages.loginAttempt && idConnectionHub === currentSession) {
        this.dialog.open(MultiSessionModalComponent, config);
      }
      /** If is closed session **/
      if (
        (messaje === signalRMessages.closedSession || messaje === signalRMessages.logoutSession) &&
        idConnectionHub === currentSession
      ) {
        this.store.dispatch(authLogoutLoad({ multiSession: true }));
        this.dialog.open(MultiSessionModalComponent, config);
      }
    });

    this.connection.onreconnecting((error) => {
      console.error('Reconnection with signalR... ', error);
    });

    this.connection.onreconnected((connectionId) => {
      console.log('SignalR is now reconnected: ', connectionId);
    });
  }

  async closeSession(): Promise<void> {
    if (this.connection.state === 'Connected') {
      const idSession: string = await lastValueFrom(
        this.store.pipe(select(selectIdSession), take(1)),
      );
      this.connection.invoke('CloseSession', idSession).then((res) => {
        this.dialog.closeAll();
      });
    }
  }

  /**
   * @function closeOtherSessions.
   * @param {string} idSession - A session will be closed
   * @description Show alert other session was closed success.
   * **/
  async closeOtherSessions(idSession: string): Promise<void> {
    await this.connection.invoke('CloseOtherSession', idSession).then(() => {
      this.store.dispatch(
        updateAlertStatus({
          data: {
            description: 'alerts.logoutAllSessionMessage',
            text: 'alerts.success',
            isShowSecondaryButton: false,
            isShowPrimaryButton: false,
            show: true,
          },
        }),
      );
    });
  }

  /**
   * Use startConnection() when session starts successfully.
   * Use stopConnection() only when user logs out or session expires.
   * To register an event handler for a specific event:
   *  @param {string} eventName - The name of the event to which the handler will subscribe.
   *  @param {function} callback - The function to be called when the event is triggered.
   *  @returns {void}
   *  @example
   *  yourHubConnection.on = function(eventName, callback) {
   *     // Implementation of the .on method
   * };
   * To invoke a remote method on the SignalR server.
   * @param {string} methodName - The name of the remote method to be invoked on the server.
   * @param {...any} args - The arguments to be passed to the remote method.
   * @returns {Promise<any>} - A Promise that resolves with the result of the remote method invocation.
   * @example
   * yourHubConnection.invoke = function(methodName, ...args) {
   *     // Implementation of the .invoke method
   * };
   * **/
  startConnection() {
    this.connection
      .start()
      .then()
      .catch((error) => {
        return console.error(error);
      });
  }

  // DOCS: Descoment if you need
  // stopConnection() {
  //   this.connection.stop();
  // }
}
