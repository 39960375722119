import { createAction, props } from '@ngrx/store';
import { buildStringNameForAction } from '@app/utils/data/common.protocol';

const typeReducer = 'Utils';

export const SET_VIEW_TYPE = createAction(
  buildStringNameForAction(typeReducer, 'Set View Type'),
  props<{ viewType: string; screenSize: number }>(),
);

export const RETURN_EMPTY = createAction(
  buildStringNameForAction(typeReducer, 'Return EMPTY ACTION'),
);

export const SET_APP_VERSION = createAction(
  buildStringNameForAction(typeReducer, 'Set version of system'),
  props<{ appVersion: string }>(),
);
