import { createAction, props } from '@ngrx/store';
import { AppSettings, Language } from '@app/core/settings/settings.model';
import { buildStringNameForAction } from '@app/utils/data/common.protocol';

const fileName = 'Settings';

export const CHANGE_LANGUAGE = createAction(
  buildStringNameForAction(fileName, 'Change Language'),
  props<{ language: Language }>(),
);
export const SET_APPLICATION_CONFIGURATION = createAction(
  buildStringNameForAction(fileName, 'Set application configuration'),
  props<{ appSettings: AppSettings }>(),
);
export const SET_INITIALIZATION_COMPLETE = createAction(
  buildStringNameForAction(fileName, 'Set application initialization complete'),
);
export const SET_BROWSER = createAction(
  buildStringNameForAction(fileName, 'Set browser'),
  props<{ currentBrowser: string }>(),
);
