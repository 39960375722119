import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { selectToken } from '@app-m-auth/store/auth.selector';
import { CryptoService } from '@app/utils/helpers/crypto.service';

@Injectable({
  providedIn: 'root',
})
export class InterceptorService {
  getTokenStore$ = this.store.select(selectToken);
  token = '';

  constructor(
    private store: Store,
    private cryptoService: CryptoService,
  ) {
    this.getTokenStore$.subscribe((params: string) => {
      // this.token = params ? this.cryptoService.decryptText(params) : '';
      this.token = params ? params : '';
    });
  }
}
