import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtmIconComponent } from './atm-icon.component';

@NgModule({
  declarations: [AtmIconComponent],
  imports: [CommonModule],
  exports: [AtmIconComponent],
})
export class AtmIconModule {}
