import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { EObjectFit, ESizeImage, ObjectFit, SizeImage } from '@app-m-shared/models/atm-image.model';
import { NgxSkeletonLoaderConfigTheme } from 'ngx-skeleton-loader/lib/ngx-skeleton-loader-config.types';
import { backgroundSkeleton } from '@app-styles/typescript/colors';

@Component({
  selector: 'atm-image',
  templateUrl: './atm-image.component.html',
  styleUrls: ['./atm-image.component.scss'],
})
/**
 * Reusable Component atm-image
 * @property {string} srcImage src of the image
 * @typedef {ObjectFit} objectFit how the content should be resized to fit its container
 * @typedef {SizeImage} size width and height of image
 * @property {ElementRef} imageElement get target of the image tag
 * @type {EventEmitter<boolean>} clickEvent emitter of click image
 */
export class AtmImageComponent implements AfterViewInit, OnChanges, OnInit {
  /** how the content should be resized to fit its container */
  @Input() objectFit: ObjectFit = 'none';
  /** width and height of image */
  @Input() size: SizeImage = 'small';
  /** src of the image */
  @Input() srcImage = '';

  /** get target of the image tag*/
  @ViewChild('imageElement') imageElement!: ElementRef;

  objectFitDefault = '';
  defaultImageSource = 'assets/images/image_default.svg';
  errorImageNativeElement = false;
  imageNativeElement: any;
  loadImage = false;
  /**
   * Emitter of click image
   * @type {EventEmitter<boolean>}
   * @memberof AtmImageComponent
   */
  @Output() clickEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  /** @readonly {ESizeImage} enum sizes image */
  readonly styleSizeImage = ESizeImage;

  constructor(
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.getSizeSkeleton();
  }
  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.imageNativeElement = this.renderer.selectRootElement(this.imageElement).nativeElement;
  }

  ngOnChanges(): void {
    this.cdr.detectChanges();
  }
  /**
   * method that emits the clickEvent value
   * @method handleClick()
   * @return void
   */
  handleClick(): void {
    this.clickEvent.emit(true);
  }

  /**
   * method get src of image element
   * @method setImage()
   * @param {string} src src image
   * @return string
   */
  setImage(src?: string): string {
    if (src) {
      if (!this.errorImageNativeElement) {
        return src;
      } else {
        return this.defaultImageSource;
      }
    }
    this.errorImageNativeElement = false;
    return '';
  }
  /**
   * method set src of image default
   * @method errorImage()
   * @return void
   */

  errorImage(): void {
    if (!this.errorImageNativeElement) {
      this.renderer.setAttribute(this.imageNativeElement, 'src', this.defaultImageSource);
    }
    this.objectFitDefault = EObjectFit.cover.toString();
    this.setImage();
  }

  /**
   * Gets the size of the skeleton based on the specified size.
   * @method getSizeSkeleton
   * @returns {NgxSkeletonLoaderConfigTheme} The CSS properties for the skeleton size.
   */
  getSizeSkeleton(): NgxSkeletonLoaderConfigTheme {
    switch (this.size) {
      case 'small':
        return { height: '32px', width: '32px', 'background-color': backgroundSkeleton };
      case 'medium':
        return { height: '40px', width: '40px', 'background-color': backgroundSkeleton };
      case 'large':
        return { height: '48px', width: '48px', 'background-color': backgroundSkeleton };
      case 'xl':
        return { height: '56px', width: '56px', 'background-color': backgroundSkeleton };
      case 'xl2':
        return { height: '64px', width: '64px', 'background-color': backgroundSkeleton };
      case 'xl3':
        return { height: '80px', width: '80px', 'background-color': backgroundSkeleton };
      case 'xl4':
        return { height: '96px', width: '96px', 'background-color': backgroundSkeleton };
    }
  }
}
