import { NgxSkeletonLoaderConfigTheme } from 'ngx-skeleton-loader/lib/ngx-skeleton-loader-config.types';

export const skeletonDefaultTheme: NgxSkeletonLoaderConfigTheme = {
  margin: '0px',
  'box-sizing': 'border-box',
  display: 'flex',
};

/**
 * Data type to specify direction of elements at skeleton
 *
 * @type {('skeleton-flex-column' | 'skeleton-flex-row')} objectFit
 * @description Available options:
 * - 'skeleton-flex-column': item at skeleton will be in column
 * - 'skeleton-flex-row': item at skeleton will be in row
 * @memberof ObjectFit
 */
export type SkeletonFlexDirection = 'skeleton-flex-column' | 'skeleton-flex-row';

/**
 * Enumeration representing different object-fit values.
 * @enum {string}
 */
export enum ESkeletonFlexDirection {
  flexColumn = 'skeleton-flex-column',
  flexRow = 'skeleton-flex-row',
}
