/**
 * Data type to specify how content should be resized to fit its container.
 *
 * @type {('fill' | 'contain' | 'cover' | 'none' | 'scale-down')} objectFit
 * @description Available options:
 * - 'fill': Content is resized to completely fill the container, maintaining aspect ratio.
 * - 'contain': Content is resized to fit completely within the container, maintaining aspect ratio.
 * - 'cover': Content is resized to completely cover the container, maintaining aspect ratio and cropping if necessary.
 * - 'none': Content is not resized.
 * - 'scale-down': Content is resize to fit within the container, maintaining aspect ratio, but never increasing beyond its original size.
 * @memberof ObjectFit
 */

export type ObjectFit = 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';

/**
 * Enumeration representing different object-fit values.
 * @enum {string}
 */
export enum EObjectFit {
  fill = 'fill',
  contain = 'contain',
  cover = 'cover',
  none = 'none',
  scaleDown = 'scale-down',
}

/**
 * Data type to specify how content should be resized to fit its container.
 *
 * @type {('small' | 'medium' | 'large' | 'xl' | 'xl2' | 'xl3' | 'xl4')} SizeImage
 * @description Available options:
 * - 'small': image size small (32 x 32)
 * - 'medium': image size medium (40 x 40)
 * - 'large': image size large (48 x 48)
 * - 'xl': image size extra large (56 x 56)
 * - 'xl2': image size 2 extra large (64 x 64)
 * - 'xl3': image size 3 extra large (80 x 80)
 * - 'xl4': image size 4 extra large (96 x 96)
 * @memberof SizeImage
 */

export type SizeImage = 'small' | 'medium' | 'large' | 'xl' | 'xl2' | 'xl3' | 'xl4';

/**
 * Enumeration representing different sizes for images.
 * @enum {string}
 */
export enum ESizeImage {
  small = 'small',
  medium = 'medium',
  large = 'large',
  xl = 'xl',
  xl2 = 'xl2',
  xl3 = 'xl3',
  xl4 = 'xl4',
}
