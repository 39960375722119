import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  EIconKey,
  ESizesIcon,
  EStatusIcon,
  IconKey,
  Icons,
  SizesIcon,
  StatusIcon,
} from '@app-m-shared/models/atm-icon.model';
import { cursorType, CursorType } from '@app/utils/models/cursor-type';

@Component({
  selector: 'atm-icon',
  templateUrl: './atm-icon.component.html',
  styleUrls: ['./atm-icon.component.scss'],
})
/**
 * Reusable Component atm-icon
 * @description This component represents an icon with various configurations.
 *
 * @property {boolean} activePointerEvents Active cursor pointer or none - Default value is `true`.
 * @property {boolean} enableEmitter Determines whether to enable the emitter or not.
 * @property {boolean} enableForceHover EnableForceHover - Default value is `false`
 * @type {IconKey} iconKey The key of the icon to be displayed
 * @property {boolean} isHover Determines whether the component is in hover state
 * @property {boolean} isDisabled Determines whether the icon is disabled or not
 * @type {SizesIcon} size The size of the icon.
 * @type {StatusIcon} statusIcon for the icon
 * @property {EventEmitter} clickEvent Event emitter for click events on the icon.
 **/
export class AtmIconComponent implements OnInit, OnChanges {
  /** Active only cursor pointer or none - Default value is `true`. */
  @Input() activePointerEvents = true;
  /** Determines whether to enable the emitter or not. */
  @Input() enableEmitter = false;
  /** A cursor type on component **/
  @Input() cursorType: CursorType = cursorType.pointer;
  /** EnableForceHover - Default value is `false`. */
  @Input() enableForceHover = false;
  /** The key of the icon to be displayed. */
  @Input({ required: true }) iconKey: IconKey = EIconKey.none;
  /** Determines whether the component is in hover state. */
  @Input() isHover = false;
  /** Determines whether the icon is disabled or not. */
  @Input() isDisabled = false;
  /** The size of the icon. */
  @Input() size: SizesIcon = ESizesIcon.Size24;
  /** Status for the icon */
  @Input() statusIcon: StatusIcon = EStatusIcon.default;
  /** Event emitter for click events on the icon. */
  @Output() clickEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  /** @readonly contains the differences icons   */
  readonly icons = Icons;
  /** @readonly contains the differences icons key   */
  readonly eIconKey = EIconKey;

  /** @readonly contains the differences status about icon   */
  readonly eStatusIconEnum = EStatusIcon;
  srcImage = '';
  constructor(private cdr: ChangeDetectorRef) {}
  ngOnInit() {
    this.getIcon();
    this.cdr.detectChanges();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes?.['iconKey']?.currentValue !== changes?.['iconKey']?.previousValue ||
      changes?.['isDisabled']?.currentValue !== changes?.['isDisabled']?.previousValue ||
      changes?.['statusIcon']?.currentValue !== changes?.['statusIcon']?.previousValue
    ) {
      this.getIcon();
    }
  }

  /**
   * @method getIcon
   * Retrieves the icon source based on the iconKey and statusIcon.
   */
  getIcon() {
    if (this.icons && this.iconKey && this.statusIcon) {
      const iconData = this.icons[this.iconKey];
      if (iconData) {
        this.srcImage = iconData[this.statusIcon] || '';
      }
    }
    if (this.isDisabled) {
      this.srcImage = this.icons[this.iconKey][this.eStatusIconEnum.disable] || '';
    }
  }
  /**
   * @method mouseenter
   * Handles mouse enter event to change icon source if the statusIcon is 'default'.
   */
  mouseenter() {
    if (this.statusIcon === this.eStatusIconEnum.default && !this.enableForceHover) {
      this.srcImage = this.icons[this.iconKey][this.eStatusIconEnum.hover] || '';
    }
  }
  /**
   * @method mouseleave
   * Handles mouse leave event to reset icon source if the statusIcon is 'default'.
   */
  mouseleave() {
    if (this.statusIcon === this.eStatusIconEnum.default && !this.enableForceHover) {
      this.srcImage = this.icons[this.iconKey][this.eStatusIconEnum.default] || '';
    }
  }
  /**
   * @method handleClick
   * Handles click event by emitting a boolean value if enableEmitter is true.
   */
  handleClick(event: Event): void {
    if (this.enableEmitter) {
      this.clickEvent.emit(true);
      event.stopPropagation();
    }
  }
  /**
   * @method getExceptionsWidth
   * Validate if the icon select
   */
  getExceptionsWidth(): boolean {
    return (
      this.iconKey === this.eIconKey.arrowLeftPage ||
      this.iconKey === this.eIconKey.arrowRightPage ||
      this.iconKey === this.eIconKey.dotSmall ||
      this.iconKey === this.eIconKey.dotMedium ||
      this.iconKey === this.eIconKey.dotLarge
    );
  }
}
