export const initialUtilsState = (): UtilsState => ({
  viewType: AppViewTypes.small,
  screenSize: 0,
  appVersion: '',
});

export interface UtilsState {
  viewType: string;
  screenSize: number;
  appVersion: string;
}

export enum AppViewTypes {
  small = 'small',
  standard = 'standard',
}
