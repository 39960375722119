import { createReducer, on } from '@ngrx/store';

import { EApiStatus } from '@app/utils/data/common.protocol';
import {
  getTotalProductsServiceFailed,
  getTotalProductsServiceLoad,
  getTotalProductsServiceSuccess,
  setStatusNumberProducts,
  setTotalProductsSystem,
  updateNumberProducts,
  updateNumberProductsDashboardSuccess,
  updateNumberProductsFailed,
  updateNumberProductsSuccess,
} from '@app/modules/core-container/user-profile/user-profile.actions';
import {
  InitUserProfileFormState,
  UserProfileFormState,
} from '@app/modules/core-container/user-profile/user-profile.models';

export const userProfileReducer = createReducer(
  InitUserProfileFormState(),
  on(
    getTotalProductsServiceLoad,
    (state: UserProfileFormState): UserProfileFormState => ({
      ...state,
      statusApiGetInfo: EApiStatus.loading,
    }),
  ),
  on(
    getTotalProductsServiceFailed,
    (state: UserProfileFormState): UserProfileFormState => ({
      ...state,
      statusApiGetInfo: EApiStatus.failed,
    }),
  ),
  on(
    getTotalProductsServiceSuccess,
    (state: UserProfileFormState, { response }): UserProfileFormState => ({
      ...state,
      totalProductsBySupplier: response,
      totalProductsBySupplierBackup: response,
      statusApiGetInfo: EApiStatus.success,
    }),
  ),
  on(
    setTotalProductsSystem,
    (state: UserProfileFormState, { value }): UserProfileFormState => ({
      ...state,
      totalProductsBySupplier: value,
    }),
  ),
  on(
    updateNumberProducts,
    (state: UserProfileFormState): UserProfileFormState => ({
      ...state,
      statusApiUpdateInfo: EApiStatus.loading,
    }),
  ),
  on(
    updateNumberProductsDashboardSuccess,
    (state: UserProfileFormState): UserProfileFormState => ({
      ...state,
      totalProductsBySupplierBackup: state.totalProductsBySupplier,
      statusApiUpdateInfo: EApiStatus.success,
    }),
  ),
  on(
    updateNumberProductsSuccess,
    (state: UserProfileFormState): UserProfileFormState => ({
      ...state,
      totalProductsBySupplierBackup: state.totalProductsBySupplier,
      statusApiUpdateInfo: EApiStatus.success,
    }),
  ),
  on(
    updateNumberProductsFailed,
    (state: UserProfileFormState): UserProfileFormState => ({
      ...state,
      statusApiUpdateInfo: EApiStatus.failed,
    }),
  ),
  on(
    setStatusNumberProducts,
    (state: UserProfileFormState): UserProfileFormState => ({
      ...state,
      statusApiUpdateInfo: EApiStatus.default,
    }),
  ),
);
