import { createSelector } from '@ngrx/store';
import { selectCatalogsState } from '@app/core/core.state';
import {
  CatalogsState,
  CatAvailability,
  CatPresentationType,
  CatUnit,
} from '@app/utils/models/catalogs/catalogs.model';
import {
  CatDisponibilidadObj,
  CatTipoPresentacionObj,
  CatUnidadObj,
  ProductoPromsy,
  QueryResultCatDisponibilidadObj,
  QueryResultCatTipoPresentacionObj,
  QueryResultCatUnidadObj,
} from '@api-promsy-models';
import { forEach, map } from 'lodash-es';
import { MolSelect } from '@app-m-shared/models/mol-select.models';
import { selectProductForm } from '@app/core/store/forms/forms.selector';

export const selectCatalogs = createSelector(selectCatalogsState, (state: CatalogsState) => state);

export const selectCatUnit = createSelector(
  selectCatalogs,
  (state: CatalogsState): CatUnit => state.catUnit,
);

export const selectCatUnitResults = createSelector(
  selectCatUnit,
  (state: CatUnit): CatUnidadObj[] => state?.data?.Results || [],
);

export const selectCatUnitOptions = createSelector(
  selectCatUnitResults,
  selectProductForm,
  (state: CatUnidadObj[], productForm: ProductoPromsy): MolSelect[] => {
    return map(
      state,
      (unit: CatUnidadObj): MolSelect => ({
        disable: false,
        selected: productForm.IdCatUnidad === unit.IdcatUnidad,
        text: unit.Unidad || '',
        value: unit.IdcatUnidad || '',
      }),
    );
  },
);

export const selectCatPresentationType = createSelector(
  selectCatalogs,
  (state: CatalogsState): CatPresentationType => state.catPresentationType,
);

export const selectCatPresentationTypeResults = createSelector(
  selectCatPresentationType,
  (state: CatPresentationType): CatTipoPresentacionObj[] => state?.data?.Results || [],
);

export const selectCatPresentationTypeOptions = createSelector(
  selectCatPresentationTypeResults,
  selectProductForm,
  (state: CatTipoPresentacionObj[], product: ProductoPromsy): MolSelect[] => {
    return map(state, (presentationType: CatTipoPresentacionObj) => ({
      disable: false,
      selected: product.IdCatTipoPresentacion === presentationType.IdcatTipoPresentacion,
      text: presentationType.TipoPresentacion || '',
      value: presentationType.IdcatTipoPresentacion || '',
    }));
  },
);

export const selectCatAvailability = createSelector(
  selectCatalogs,
  (state: CatalogsState): CatAvailability => state.catAvailability,
);
export const selectNeedsToReloadCatUnit = createSelector(
  selectCatUnit,
  (state: CatUnit): boolean => state.needsToReload,
);
export const selectCatUnitData = createSelector(
  selectCatUnit,
  (state: CatUnit): QueryResultCatUnidadObj => state.data,
);

export const selectNeedsToReloadCatPresentationType = createSelector(
  selectCatPresentationType,
  (state: CatPresentationType): boolean => state.needsToReload,
);
export const selectCatPresentationTypeData = createSelector(
  selectCatPresentationType,
  (state: CatPresentationType): QueryResultCatTipoPresentacionObj => state.data,
);
export const selectCatTipoPresentationDataResult = createSelector(
  selectCatPresentationTypeData,
  (state: QueryResultCatTipoPresentacionObj): CatTipoPresentacionObj[] => state.Results || [],
);

export const selectNeedsToReloadCatAvailability = createSelector(
  selectCatAvailability,
  (state: CatAvailability): boolean => state.needsToReload,
);
export const selectCatAvailabilityData = createSelector(
  selectCatAvailability,
  (state: CatAvailability): QueryResultCatDisponibilidadObj => state.data,
);
export const selectCatAvailabilityDataResult = createSelector(
  selectCatAvailabilityData,
  (state: QueryResultCatDisponibilidadObj): CatDisponibilidadObj[] => state.Results || [],
);
