import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  EIconKey,
  ESizesIcon,
  EStatusIcon,
  IconKey,
  StatusIcon,
} from '@app-m-shared/models/atm-icon.model';
import { fontWeight } from '@app/utils/models/font-weight';
import { cursorType } from '@app/utils/models/cursor-type';
import { EFontColor } from '@app/utils/models/e-font-color';
import {
  ButtonSizeKey,
  ButtonTypeKey,
  ColorBtnConfig,
  EButtonSizes,
  EButtonTypes,
} from '@app-m-shared/models/mol-button.model';

@Component({
  selector: 'mol-button',
  templateUrl: './mol-button.component.html',
  styleUrls: ['./mol-button.component.scss'],
})

/**
 * @property {ButtonTypeKey} type - style type that will use on button
 * @property {string} firstIcon - icon that will use at start of button (optional)
 * @property {string} secondIcon - icon that will use at the end of button (optional)
 * @property {string} text - text that will use on button
 * @property {boolean} spaceFilling - property to use 100% of space or only content width
 * @property {ButtonSizeKey} size - size of button
 * @property {boolean} select - status indicating that the button is selected
 * @property {boolean} EventEmitter() - flag to enable emit on handleSubmit()
 **/
export class MolButtonComponent {
  readonly colorBtn: ColorBtnConfig = COLOR_BTN;
  readonly eButtonSizes = EButtonSizes;
  readonly eButtonTypes = EButtonTypes;
  readonly eCursorType = cursorType;
  readonly eFontWeight = fontWeight;
  readonly eIconSizes = ESizesIcon;
  readonly eStatusIcon = EStatusIcon;
  readonly eFontColor = EFontColor;
  isHover = false;
  /** Disabled status on button **/
  @Input() disable = false;
  /** First icon on Button **/
  @Input() firstIcon: IconKey = EIconKey.none;
  /** Second icon on button **/
  @Input() secondIcon: IconKey = EIconKey.none;
  /** Selected status on button **/
  @Input() select = false;
  /** A size of button **/
  @Input() size: ButtonSizeKey = EButtonSizes.s;
  /** Enable width 100% **/
  @Input() spaceFilling = false;
  /** Button type style **/
  @Input() type: ButtonTypeKey = EButtonTypes.primary;
  /** An event that button emit **/
  @Output() submitEvent: EventEmitter<void> = new EventEmitter<void>();

  handleSubmit() {
    if (!this.disable) {
      this.submitEvent.emit();
    }
  }
  handleStatusIcon(): StatusIcon {
    if (this.type === this.eButtonTypes.primary || this.type === this.eButtonTypes.destructive) {
      return this.disable ? this.eStatusIcon.disable : this.eStatusIcon.white;
    } else {
      return this.disable ? this.eStatusIcon.disable : this.eStatusIcon.default;
    }
  }
}

const COLOR_BTN: ColorBtnConfig = {
  primary: EFontColor.secondary,
  secondary: EFontColor.primary,
  tertiary: EFontColor.primary,
  destructive: EFontColor.secondary,
  disable: EFontColor.disable,
};
