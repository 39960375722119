/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiPromsyConfiguration as __Configuration } from '../api-promsy-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { GMUsuarioPromsyDetalle } from '../models/gmusuario-promsy-detalle';
import { UsuarioPromsy } from '../models/usuario-promsy';
import { QueryResultUsuarioPromsy } from '../models/query-result-usuario-promsy';
import { QueryInfo } from '../models/query-info';
@Injectable({
  providedIn: 'root',
})
class SistemaUsuariosService extends __BaseService {
  static readonly UsuarioGuardarOActualizarUsaurioDetallePath = '/Usuario/RegistrarUsuario';
  static readonly UsuarioObtenerPath = '/Usuario';
  static readonly UsuarioGuardarOActualizarPath = '/Usuario';
  static readonly UsuarioQueryResultPath = '/Usuario';
  static readonly UsuarioDesactivarPath = '/Usuario';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * GuardarOActualizarUsaurioDetalle Usuario
   * @param params The `SistemaUsuariosService.UsuarioGuardarOActualizarUsaurioDetalleParams` containing the following parameters:
   *
   * - `gMUsuarioPromsyDetalle`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  UsuarioGuardarOActualizarUsaurioDetalleResponse(params: SistemaUsuariosService.UsuarioGuardarOActualizarUsaurioDetalleParams): __Observable<__StrictHttpResponse<GMUsuarioPromsyDetalle>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.gMUsuarioPromsyDetalle;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Usuario/RegistrarUsuario`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<GMUsuarioPromsyDetalle>;
      })
    );
  }
  /**
   * GuardarOActualizarUsaurioDetalle Usuario
   * @param params The `SistemaUsuariosService.UsuarioGuardarOActualizarUsaurioDetalleParams` containing the following parameters:
   *
   * - `gMUsuarioPromsyDetalle`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  UsuarioGuardarOActualizarUsaurioDetalle(params: SistemaUsuariosService.UsuarioGuardarOActualizarUsaurioDetalleParams): __Observable<GMUsuarioPromsyDetalle> {
    return this.UsuarioGuardarOActualizarUsaurioDetalleResponse(params).pipe(
      __map(_r => _r.body as GMUsuarioPromsyDetalle)
    );
  }

  /**
   * Obtiene un usuario por su id
   * @param params The `SistemaUsuariosService.UsuarioObtenerParams` containing the following parameters:
   *
   * - `idUsuario`: Identificador del usuario
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  UsuarioObtenerResponse(params: SistemaUsuariosService.UsuarioObtenerParams): __Observable<__StrictHttpResponse<UsuarioPromsy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.idUsuario != null) __params = __params.set('idUsuario', params.idUsuario.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/Usuario`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<UsuarioPromsy>;
      })
    );
  }
  /**
   * Obtiene un usuario por su id
   * @param params The `SistemaUsuariosService.UsuarioObtenerParams` containing the following parameters:
   *
   * - `idUsuario`: Identificador del usuario
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  UsuarioObtener(params: SistemaUsuariosService.UsuarioObtenerParams): __Observable<UsuarioPromsy> {
    return this.UsuarioObtenerResponse(params).pipe(
      __map(_r => _r.body as UsuarioPromsy)
    );
  }

  /**
   * Guarda o acualiza un usuario
   * @param params The `SistemaUsuariosService.UsuarioGuardarOActualizarParams` containing the following parameters:
   *
   * - `usuario`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  UsuarioGuardarOActualizarResponse(params: SistemaUsuariosService.UsuarioGuardarOActualizarParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.usuario;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/Usuario`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Guarda o acualiza un usuario
   * @param params The `SistemaUsuariosService.UsuarioGuardarOActualizarParams` containing the following parameters:
   *
   * - `usuario`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  UsuarioGuardarOActualizar(params: SistemaUsuariosService.UsuarioGuardarOActualizarParams): __Observable<string> {
    return this.UsuarioGuardarOActualizarResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * Obtiene una lista de usuarios que coinciden con un filtro de busqueda
   * @param params The `SistemaUsuariosService.UsuarioQueryResultParams` containing the following parameters:
   *
   * - `info`: Filtros de búsqueda de tipo QueryInfo
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  UsuarioQueryResultResponse(params: SistemaUsuariosService.UsuarioQueryResultParams): __Observable<__StrictHttpResponse<QueryResultUsuarioPromsy>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.info;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/Usuario`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryResultUsuarioPromsy>;
      })
    );
  }
  /**
   * Obtiene una lista de usuarios que coinciden con un filtro de busqueda
   * @param params The `SistemaUsuariosService.UsuarioQueryResultParams` containing the following parameters:
   *
   * - `info`: Filtros de búsqueda de tipo QueryInfo
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  UsuarioQueryResult(params: SistemaUsuariosService.UsuarioQueryResultParams): __Observable<QueryResultUsuarioPromsy> {
    return this.UsuarioQueryResultResponse(params).pipe(
      __map(_r => _r.body as QueryResultUsuarioPromsy)
    );
  }

  /**
   * Desactiva un usuario
   * @param params The `SistemaUsuariosService.UsuarioDesactivarParams` containing the following parameters:
   *
   * - `idUsuario`: Identificador del usuario
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  UsuarioDesactivarResponse(params: SistemaUsuariosService.UsuarioDesactivarParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.idUsuario != null) __params = __params.set('idUsuario', params.idUsuario.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/Usuario`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * Desactiva un usuario
   * @param params The `SistemaUsuariosService.UsuarioDesactivarParams` containing the following parameters:
   *
   * - `idUsuario`: Identificador del usuario
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  UsuarioDesactivar(params: SistemaUsuariosService.UsuarioDesactivarParams): __Observable<string> {
    return this.UsuarioDesactivarResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }
}

module SistemaUsuariosService {

  /**
   * Parameters for UsuarioGuardarOActualizarUsaurioDetalle
   */
  export interface UsuarioGuardarOActualizarUsaurioDetalleParams {
    gMUsuarioPromsyDetalle: GMUsuarioPromsyDetalle;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for UsuarioObtener
   */
  export interface UsuarioObtenerParams {

    /**
     * Identificador del usuario
     */
    idUsuario: string;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for UsuarioGuardarOActualizar
   */
  export interface UsuarioGuardarOActualizarParams {
    usuario: UsuarioPromsy;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for UsuarioQueryResult
   */
  export interface UsuarioQueryResultParams {

    /**
     * Filtros de búsqueda de tipo QueryInfo
     */
    info: QueryInfo;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for UsuarioDesactivar
   */
  export interface UsuarioDesactivarParams {

    /**
     * Identificador del usuario
     */
    idUsuario: string;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }
}

export { SistemaUsuariosService }
