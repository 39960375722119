/* Store Imports */
import { createSelector } from '@ngrx/store';
import { selectAuthState, selectRouterState } from '@app/core/core.state';
import { AuthState } from '@app/utils/models/auth/auth.models';
import { appRoutes } from '@app/core/routes';

/* Selectors Imports */

export const selectCurrentRoute = createSelector(selectRouterState, (router) => {
  const currentRoute = router?.state?.url;
  const loginRoute = `/${appRoutes.auth}/${appRoutes.login}`;
  const twoFactorRoute = `/${appRoutes.auth}/${appRoutes.twoFactorAuth}`;

  if (currentRoute == loginRoute || currentRoute === twoFactorRoute) {
    return '/';
  }
  return router?.state?.url;
});
export const selectAuth = createSelector(selectAuthState, (state: AuthState) => state);
