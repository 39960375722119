import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Browser, browserRegexFinder, Browsers } from '@app/utils/helpers/shared';
import { SET_APPLICATION_CONFIGURATION, SET_BROWSER } from '@app/core/settings/settings.actions';
import packageJson from '@app-root/package.json';
import { SET_APP_VERSION } from '@app/core/utils/utils.action';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { AppSettings } from '@app/core/settings/settings.model';
import { NGXLogger } from 'ngx-logger';
import { generateMessage, LOG_FAILED } from '@app/utils/helpers/logger';

const fileName = 'app-settings.service';
@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  constructor(
    private httpClient: HttpClient,
    private store: Store,
    private loggerService: NGXLogger,
  ) {}

  setCurrentBrowser(): void {
    const browserMatches: RegExpExecArray | null = browserRegexFinder.exec(navigator?.userAgent);
    if (!browserMatches) {
      return;
    }
    const keyBrowser: string = browserMatches[0];
    const currentBrowser: Browser = Browsers[keyBrowser as keyof typeof Browsers] || Browsers.Other;
    this.store.dispatch(SET_BROWSER({ currentBrowser }));
  }

  setVersionApp(): void {
    const packageVersion = JSON.stringify(packageJson);
    this.store.dispatch(SET_APP_VERSION({ appVersion: JSON.parse(packageVersion)?.version }));
  }

  setFunctionConfigObser() {
    return of('').pipe(
      exhaustMap(() => {
        return this.httpClient.get<AppSettings>('assets/app-settings.json').pipe(
          map((appSettings: AppSettings) => {
            this.store.dispatch(
              SET_APPLICATION_CONFIGURATION({
                appSettings,
              }),
            );
            return true;
          }),
          catchError((error) => {
            this.loggerService.error(
              generateMessage(fileName, LOG_FAILED, 'al obtener la configuración del sistema'),
              error,
            );
            return of(false);
          }),
        );
      }),
    );
  }
}
