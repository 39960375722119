import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { filter, map, Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { AppToken } from '../../../utils/models/auth/token.model';
import { AuthState } from '../../../utils/models/auth/auth.models';
import { UPDATE_TOKEN } from './auth.actions';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly urlApi;

  constructor(
    private http: HttpClient,
    private store: Store,
  ) {
    this.urlApi = environment.apisServerUrl;
  }

  connectTokenResponse(user: any): Observable<HttpResponse<AppToken>> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    const body = new URLSearchParams();
    body.append('grant_type', 'password');
    body.append('client_id', 'ro.client');
    body.append('scope', 'openid profile roles offline_access default');
    body.append('UserName', user.username);
    body.append('Password', user.password);
    body.append('idConnectionHub', user.idConnectionHub);

    const req = new HttpRequest<any>(
      'POST',
      `${environment.identityServerUrl}:${environment.identityPort}/connect/token`,
      body,
      {
        headers,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        return _r as HttpResponse<AppToken>;
      }),
    );
  }

  connectToken(user: any): Observable<AppToken> {
    return this.connectTokenResponse(user).pipe(map((_r) => _r.body as AppToken));
  }

  refreshTokenResponse(
    refreshToken: string,
    idSession: string,
  ): Observable<HttpResponse<AppToken>> {
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    const body = new URLSearchParams();
    body.append('grant_type', 'refresh_token');
    body.append('client_id', 'ro.client');
    body.append('scope', 'openid profile roles offline_access default');
    body.append('refresh_token', refreshToken);
    body.append('idSession', idSession);

    const req = new HttpRequest<any>(
      'POST',
      `${environment.identityServerUrl}:${environment.identityPort}/connect/token`,
      body,
      {
        headers,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        return _r as HttpResponse<AppToken>;
      }),
    );
  }

  refreshToken(refreshToken: string, idSession: string): Observable<AppToken> {
    return this.refreshTokenResponse(refreshToken, idSession).pipe(
      map((_r) => _r.body as AppToken),
    );
  }

  userInfoResponse(token: string): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Authorization', `Bearer ${token}`);
    const body: any = null;
    const req = new HttpRequest<any>(
      'GET',
      `${environment.identityServerUrl}:${environment.identityPort}/connect/userinfo`,
      body,
      {
        headers,
        responseType: 'json',
      },
    );

    return this.http.request<any>(req).pipe(
      filter((_r) => _r instanceof HttpResponse),
      map((_r) => {
        return _r as HttpResponse<any>;
      }),
    );
  }

  userInfo(token: string): Observable<any> {
    return this.userInfoResponse(token).pipe(map((_r) => _r.body));
  }

  refreshTokenFromInit(authState: AuthState) {
    this.store.dispatch(UPDATE_TOKEN({ auth: authState, delay: false }));
  }
}
