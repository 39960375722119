import { createAction, props } from '@ngrx/store';
import { buildStringNameForAction } from '@app/utils/data/common.protocol';
import { Message, ProductoPromsy } from '@api-promsy-models';

const typeApi = 'forms-api';
const typeReducer = 'forms-reducer';

export const initProductForm = createAction(
  buildStringNameForAction(typeReducer, 'Init Product Form'),
);

export const selectProductToEdit = createAction(
  buildStringNameForAction(typeReducer, 'Select Product To Edit'),
  props<{ product: ProductoPromsy }>(),
);

export const saveProductForm = createAction(
  buildStringNameForAction(typeReducer, 'Save Product Form'),
  props<{ product: ProductoPromsy }>(),
);
export const saveProductFormSuccess = createAction(
  buildStringNameForAction(typeReducer, 'Save Product Form Success'),
  props<{ message: Message[]; isNewProduct?: boolean }>(),
);
export const saveProductFormFailed = createAction(
  buildStringNameForAction(typeReducer, 'Save Product Form Failed'),
);
export const seeNewProductDetail = createAction(
  buildStringNameForAction(typeReducer, 'See new product detail'),
);
// CAS VALIDATION
export const validCas = createAction(
  buildStringNameForAction(typeApi, 'Valid Cas '),
  props<{ cas: string }>(),
);
export const validCasSuccess = createAction(
  buildStringNameForAction(typeApi, 'Valid Cas Success'),
  props<{ isValid: boolean }>(),
);
export const validCasFailed = createAction(buildStringNameForAction(typeApi, 'Valid Cas Failed'));
