/**
 * Represents the possible text decorations.
 * @typedef {'none' | 'underline'} TextDecoration
 */
export type TextDecoration = 'none' | 'underline' | 'lineThrough';

/**
 * Enum representing different text decorations.
 * @readonly
 * @enum {string}
 */
export enum ETextDecoration {
  /**
   * No text decoration.
   */
  none = 'none',

  /**
   * Underline text decoration.
   */
  underline = 'underline',

  /**
   * Line through text decoration.
   */
  lineThrough = 'lineThrough',
}
