import { AuthService } from '@app-m-auth/store/auth.service';
import { LocalStorageService } from '@app/core/storage/local-storage.service';
import { AppSettingsService } from '@app/core/initializer/app-settings.service';
import { Store } from '@ngrx/store';
import { AUTH_KEY } from '@app/utils/data/common.protocol';
import { AuthState } from '@app/utils/models/auth/auth.models';
import { selectInitializationComplete } from '@app/core/settings/settings.selectors';
import { SET_INITIALIZATION_COMPLETE } from '@app/core/settings/settings.actions';
import { SignalRService } from '@app/services/signalR/signal-r.service';

export function appInitializer(
  authService: AuthService,
  localStorageService: LocalStorageService,
  appSettingsService: AppSettingsService,
  store: Store,
  signalR: SignalRService,
) {
  return () =>
    new Promise<void>((resolve, reject) => {
      // DOCS: Obtiene el navegador utilizado
      appSettingsService.setCurrentBrowser();

      // DOCS: Cargar la verison del package.json
      appSettingsService.setVersionApp();

      // DOCS: Inializar el servicio de SignalR
      signalR.startConnection();

      // // DOCS: Inicio la obtención de información sobre las funciones de los usuarios
      appSettingsService.setFunctionConfigObser().subscribe((success: boolean) => {
        if (!success) {
          reject();
        }
        const packUserSessionData: AuthState | null = localStorageService.getItem(AUTH_KEY);
        if (packUserSessionData?.isAuthenticated && packUserSessionData?.token?.access_token) {
          // DOCS: Si existe la sesión, se actualiza el token
          authService.refreshTokenFromInit(packUserSessionData);
          store.select(selectInitializationComplete).subscribe((resp) => {
            if (resp) {
              resolve();
            }
          });
        } else {
          // DOCS: Si no existe la sesión continuo con el arranque de la app
          store.dispatch(SET_INITIALIZATION_COMPLETE());
          resolve();
        }
      });
    });
}
