import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TermsAndConditionsDialogComponent } from '@app-m-auth/terms-and-conditions/terms-and-conditions-dialog/terms-and-conditions-dialog.component';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss'],
})
export class TermsAndConditionsComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    const config: MatDialogConfig = {
      width: '651px',
      panelClass: 'content-dialog',
      backdropClass: 'backdrop-dialog',
      disableClose: true,
    };
    this.dialog.open(TermsAndConditionsDialogComponent, config);
  }
}
