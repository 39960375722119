import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { appRoutes } from './core/routes';
import { systemGuard } from '@app/core/guards/system.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: appRoutes.auth,
    pathMatch: 'full',
  },
  {
    path: appRoutes.auth,
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: appRoutes.appSubRoutes.base,
    loadChildren: () =>
      import('./modules/core-container/core-container.module').then((m) => m.CoreContainerModule),
    canMatch: [systemGuard],
  },
  {
    path: '**',
    loadChildren: () =>
      import('./modules/not-found/not-found.module').then((m) => m.NotFoundModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
