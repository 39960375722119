import { createAction, props } from '@ngrx/store';
import { buildStringNameForAction } from '@app/utils/data/common.protocol';
import {
  QueryResultCatDisponibilidadObj,
  QueryResultCatTipoPresentacionObj,
  QueryResultCatUnidadObj,
} from '@api-promsy-models';

const typeApi = 'product-details-api';
const typeReducer = 'product-details';

export const empty = createAction(buildStringNameForAction(typeReducer, 'Empty'));
export const getCatUnitLoad = createAction(buildStringNameForAction(typeApi, 'Get Cat Unit Load'));
export const getCatUnitSuccess = createAction(
  buildStringNameForAction(typeApi, 'Get Cat Unit Success'),
  props<{ queryResult: QueryResultCatUnidadObj }>(),
);
export const getCatUnitFailed = createAction(
  buildStringNameForAction(typeApi, 'Get Cat Unit Failed'),
);
export const getCatPresentationTypeLoad = createAction(
  buildStringNameForAction(typeApi, 'Get Cat Presentation Type Load'),
);
export const getCatPresentationTypeSuccess = createAction(
  buildStringNameForAction(typeApi, 'Get Cat Presentation Type Success'),
  props<{ queryResult: QueryResultCatTipoPresentacionObj }>(),
);
export const getCatPresentationTypeFailed = createAction(
  buildStringNameForAction(typeApi, 'Get Cat Presentation Type Failed'),
);
export const getCatAvailabilityLoad = createAction(
  buildStringNameForAction(typeApi, 'Get Cat Availability Load'),
);
export const getCatAvailabilitySuccess = createAction(
  buildStringNameForAction(typeApi, 'Get Cat Availability Success'),
  props<{ queryResult: QueryResultCatDisponibilidadObj }>(),
);
export const getCatAvailabilityFailed = createAction(
  buildStringNameForAction(typeApi, 'Get Cat Availability Failed'),
);
