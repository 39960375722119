/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * Global configuration for ApiPromsy services
 */
@Injectable({
  providedIn: 'root',
})
export class ApiPromsyConfiguration {
  rootUrl: string = 'https://172.24.20.21/Promsy.Api';
}

export interface ApiPromsyConfigurationInterface {
  rootUrl?: string;
}
