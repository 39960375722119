import { AlertPosition, AlertType, EAlertPosition, EAlertType } from '@app-m-shared/models/alert';
import {
  ButtonFontTypeKey,
  ButtonTypeKey,
  EButtonTypes,
} from '@app-m-shared/models/mol-button.model';

export interface AlertConfigState {
  show: boolean;
  text: string;
  position?: AlertPosition;
  type?: AlertType;
  description: string;
  isShowPrimaryButton?: boolean;
  isShowSecondaryButton?: boolean;
  isShowLinkButton?: boolean;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  linkButtonText?: string;
  linkButtonActionParams?: any;
  primaryButtonType?: ButtonTypeKey;
  secondaryButtonType?: ButtonTypeKey;
}

export const InitialAlertConfigState = (): AlertConfigState => ({
  show: false,
  text: '',
  description: '',
  isShowPrimaryButton: false,
  isShowSecondaryButton: false,
  isShowLinkButton: false,
  position: EAlertPosition.bottom,
  type: EAlertType.success,
  primaryButtonText: '',
  primaryButtonType: EButtonTypes.primary,
  secondaryButtonText: '',
  linkButtonText: '',
  secondaryButtonType: EButtonTypes.secondary,
});
