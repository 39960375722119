export const browserRegexFinder = /firefox|opr|chrome(?!.*opr)|safari(?!.*opr)/i;
export enum Browsers {
  Chrome = 'Chrome',
  FireFox = 'Firefox',
  Safari = 'Safari',
  Opera = 'OPR',
  Other = 'Other',
}

export type Browser = 'Chrome' | 'Firefox' | 'Safari' | 'OPR' | 'Other';

export type BrowserConfig = { [key: string]: Browser };
