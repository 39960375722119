import { Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ACCEPT_TERMS, authLogoutLoad } from '@app-m-auth/store/auth.actions';
import { EButtonSizes, EButtonTypes } from '@app-m-shared/models/mol-button.model';
import { DialogRef } from '@angular/cdk/dialog';
import { EAlertType } from '@app-m-shared/models/alert';
import { VUsuarioPromsy } from '@api-promsy-models';
import { lastValueFrom, take } from 'rxjs';
import { selectWhoAmiInfo } from '@app-m-auth/store/auth.selector';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EIconKey } from '@app-m-shared/models/atm-icon.model';
import { TermsModel } from '@app/utils/models/terms-model';
import { fontWeight } from '@app/utils/models/font-weight';

@Component({
  selector: 'app-terms-and-conditions-dialog',
  templateUrl: './terms-and-conditions-dialog.component.html',
  styleUrls: ['./terms-and-conditions-dialog.component.scss'],
})
export class TermsAndConditionsDialogComponent {
  @ViewChild('contentScroll') content!: ElementRef;
  enableAccept = false;
  readonly eButtonSizes = EButtonSizes;
  readonly eButtonTypes = EButtonTypes;
  readonly eAlertType = EAlertType;
  readonly eIconKey = EIconKey;
  readonly eFontWight = fontWeight;

  showAlert = false;
  completeView = false;

  constructor(
    private store: Store<any>,
    private dialogRef: DialogRef<TermsAndConditionsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TermsModel,
  ) {}

  onScroll(): void {
    const container = this.content.nativeElement;
    const diferenceScroller =
      Number(Math.floor(container.scrollHeight - container.scrollTop)) - container.clientHeight;
    const hasBeenScrollEnd = diferenceScroller <= 5 && diferenceScroller >= 0;
    if (hasBeenScrollEnd && !this.enableAccept) {
      this.enableAccept = true;
    }
  }

  async acceptTerms(): Promise<void> {
    if (this.showAlert) {
      return;
    }
    const whoAmI: VUsuarioPromsy = await lastValueFrom(
      this.store.pipe(select(selectWhoAmiInfo), take(1)),
    );
    this.store.dispatch(
      ACCEPT_TERMS({ user: { ...whoAmI, AceptaTerminos: true }, IdDialogClose: this.dialogRef.id }),
    );
  }

  decline(): void {
    if (this.showAlert) {
      return;
    }
    this.completeView = true;
    this.showAlert = true;
  }

  confirmOut(): void {
    this.dialogRef.close();
    this.store.dispatch(authLogoutLoad({ multiSession: false }));
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  confirmStay(): void {
    if (!this.showAlert) {
      return;
    }
    this.showAlert = false;
  }
}
