import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Idle } from '@ng-idle/core';
import { catchError, mergeMap, Observable, of, tap, throwError } from 'rxjs';
import { environment } from '@environments/environment';
import { regexValidatorForUrl } from '@app/core/helpers/shared.helpers';
import { InterceptorService } from '@app/core/interceptors/interceptor.service';
import { getHeadersGet, getHeadersPost } from '@app/core/interceptors/api-headers';
import { isString } from 'lodash-es';

// const FILE_NAME = 'http-auth.interceptor';

@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  constructor(
    // private store: Store,
    // private session: LocalStorageService,
    private _idle: Idle,
    private interceptorService: InterceptorService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let isIdentityRequest = false;
    // DOCS: No le pega al token a las peticiones relacionadas al identity
    if (request.url.includes('connect') || request.url.includes('SessionHub')) {
      isIdentityRequest = true;
    }
    this._idle.interrupt();
    // DOCS: Se colocan las IP de environment según el entorno sin importar las generadas automáticamente
    request = request.clone({
      url: request.url.replace(
        regexValidatorForUrl,
        isIdentityRequest
          ? `${environment.identityServerUrl.toString()}:${environment.identityPort.toString()}${environment.identityBaseHref}`
          : environment.apisPort !== 0
            ? `${environment.apisServerUrl.toString()}:${environment.apisPort.toString()}`
            : `${environment.apisServerUrl.toString()}`,
      ),
    });

    const { method, headers } = request;
    const token = this.interceptorService.token;

    const handleRoute = (currentToken: string): Observable<HttpEvent<any>> => {
      let shouldSetHeaders = false;
      if (currentToken && !headers.get('Authorization')) {
        shouldSetHeaders = true;
      }
      if (shouldSetHeaders && !isIdentityRequest) {
        request = request.clone({
          headers: method === 'GET' ? getHeadersGet(token) : getHeadersPost(token),
        });
      }

      return next.handle(request).pipe(
        tap(),
        catchError((err: HttpErrorResponse) => {
          let { error } = err;
          error = error && isString(error) ? JSON.parse(error) : error;
          // const message = this.getErrorMessage(error);
          // this.store.dispatch(SET_LOADING({payload: false}));
          // DOCS: Si es alguna petición de login no se muestra el modal de error
          if (!request.url.includes('connect/token')) {
            // this.store.dispatch(SET_LOADING_ERROR({active: true, message}));
          }
          return throwError(() => error);
        }),
      );
    };

    return of(null).pipe(
      mergeMap(() => {
        return handleRoute(token);
      }),
    );
  }

  /*getErrorMessage(error: any) {
    const getInnerExceptionErrorMessage = (error: any): any => {
      if (!_.isEmpty(error.InnerException) && error.InnerException !== null) {
        return getInnerExceptionErrorMessage(error.InnerException);
      } else {
        return error.Message ? error.Message : 'Ocurrió un error en la operación';
      }
    };

    const getFluentValidationErrorMessage = (error: any) => {
      let errorsArray: any[] = [];
      const add = (errors: any) => {
        for (const key in errors) {
          errorsArray = _.compact(_.concat(errorsArray, `${key}: ${errors[key].join(', ')}`));
        }
      };
      if (Array.isArray(error.ModelState)) {
        _.forEach(error.ModelState, (obj: object) => {
          add(obj);
        });
      } else {
        add(error.ModelState);
      }
      return !_.isEmpty(errorsArray) ? errorsArray.join(', ') : 'Ocurrió un error en la operación';
    };

    return Object.prototype.hasOwnProperty.call(error, 'InnerException')
      ? getInnerExceptionErrorMessage(error)
      : getFluentValidationErrorMessage(error);
  }*/
}
