import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from '@app-core/internationalization/transloco-root.module';
import { CoreModule } from '@app/core/core.module';
import { MatDialogModule } from '@angular/material/dialog';
import { TermsAndConditionsModule } from '@app-m-auth/terms-and-conditions/terms-and-conditions.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslocoRootModule,
    CoreModule,
    MatDialogModule,
    TermsAndConditionsModule,
  ],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
