import { SERVER_URLS } from './baseURL';

export const environment = {
  appName: 'Promsy',
  environmentServerName: 'dev',
  production: false,
  apisServerUrl: SERVER_URLS.apiDev,
  apisAdditional: '',
  apisPort: SERVER_URLS.apiPortDev,
  identityServerUrl: SERVER_URLS.identityDev,
  identityPort: SERVER_URLS.identityPortDev,
  appPathPrefix: 'en',
  appBaseHref: '',
  identityBaseHref: '',
};
