import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonSizeKey, EButtonSizes } from '@app-m-shared/models/mol-button.model';
import { EIconKey, ESizesIcon, EStatusIcon, IconKey } from '@app-m-shared/models/atm-icon.model';
import { fontWeight } from '@app/utils/models/font-weight';
import { cursorType } from '@app/utils/models/cursor-type';
import { ETextDecoration } from '@app/utils/models/reactive-forms/e-text-decoration';
import { EFontColor, FontColor } from '@app/utils/models/e-font-color';

@Component({
  selector: 'mol-button-link',
  templateUrl: './mol-button-link.component.html',
  styleUrls: ['./mol-button-link.component.scss'],
})
/**
 * @property {string} firstIcon - icon that will use at start of button (optional)
 * @property {string} secondIcon - icon that will use at the end of button (optional)
 * @property {string} text - text that will use on button
 * @property {ButtonSizeKey} size - size of button
 * @property {FontColor} fontColor - font color on text for button
 * @property {boolean} select -status indicating that the button is selected
 * @property {boolean} EventEmitter() - flag to enable emit on handleSubmit()
 **/
export class MolButtonLinkComponent {
  readonly eButtonSizes = EButtonSizes;
  readonly eCursorType = cursorType;
  readonly eFontWeight = fontWeight;
  readonly eStatusIcon = EStatusIcon;
  readonly eTextDecoration = ETextDecoration;
  readonly iconSizes = ESizesIcon;
  isHover = false;
  /** Disabled status on button **/
  @Input() disable: boolean = false;
  /** First icon on Button **/
  @Input() firstIcon: IconKey = EIconKey.none;
  /** Text color for button **/
  @Input() fontColor: FontColor = EFontColor.default;
  /** Second icon on button **/
  @Input() secondIcon: IconKey = EIconKey.none;
  /** A size of button **/
  @Input() size: ButtonSizeKey = EButtonSizes.s;
  /** Selected status on button **/
  @Input() select = false;
  /** An event that button emit **/
  @Output() submitEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  // TODO: add here the cases of the colors that will be added in the future
  handleFontColor(): FontColor {
    switch (this.fontColor) {
      case EFontColor.buttonLink:
        return this.isHover || this.select
          ? EFontColor.linkHover
          : this.disable
            ? EFontColor.disable
            : this.fontColor;
      default:
        return this.fontColor;
    }
  }
  handleSubmit() {
    if (!this.disable) {
      this.submitEvent.emit();
    }
  }
}
