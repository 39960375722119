import { Injectable } from '@angular/core';
import { update } from 'lodash-es';
import { APP_PREFIX, AUTH_KEY } from '@app/utils/data/common.protocol';
import { AuthState } from '@app/utils/models/auth/auth.models';
import { AppToken } from '@app/utils/models/auth/token.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor(private router: Router) {}
  static loadInitialState() {
    return Object.keys(sessionStorage).reduce((state: any, storageKey) => {
      if (storageKey.includes(APP_PREFIX)) {
        const stateKeys = storageKey
          .replace(APP_PREFIX, '')
          .toLowerCase()
          .split('.')
          .map((key) =>
            key
              .split('-')
              .map((token, index) =>
                index === 0 ? token : token.charAt(0).toUpperCase() + token.slice(1),
              )
              .join(''),
          );
        let currentStateRef = state;
        stateKeys.forEach((key, index) => {
          if (index === stateKeys.length - 1) {
            currentStateRef[key] = JSON.parse(sessionStorage.getItem(storageKey) ?? '');
            return;
          }
          currentStateRef[key] = currentStateRef[key] || {};
          currentStateRef = currentStateRef[key];
        });
      }
      return state;
    }, {});
  }

  setItem(key: string, value: any): void {
    sessionStorage.setItem(`${APP_PREFIX}${key}`, JSON.stringify(value));
  }

  getItem(key: string): any {
    const json = sessionStorage.getItem(`${APP_PREFIX}${key}`) ?? '';

    if (!json) {
      return null;
    }

    return JSON.parse(json);
  }

  isLogged() {
    const session: AuthState = this.getItem(AUTH_KEY);
    const token: AppToken = session.token;
    return !!token || null;
  }

  updateItem(key: string, value: any, path: string): void {
    const packedData: any = this.getItem(key);
    if (packedData) {
      update(packedData, path, () => {
        return value;
      });
      this.setItem(key, packedData);
    }
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(`${APP_PREFIX}${key}`);
  }
}
