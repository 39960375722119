const coreRoutes = {
  dashboard: 'dashboard',
  products: 'products',
  productDetails: 'product-details',
};

const AppSubRoutes = {
  base: 'app',
  coreRoutes: coreRoutes,
  id: '/:id',
};

export const appRoutes = {
  auth: 'auth',
  login: 'login',
  twoFactorAuth: 'two-factor-auth',
  appSubRoutes: AppSubRoutes,
  notFound: 'not-found',
  termsAndConditions: 'terms-and-conditions',
};
