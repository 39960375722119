import { FontColor } from '@app/utils/models/e-font-color';

/**
 * @type {'primary', 'secondary', 'terriary', 'destru'} - ButtonTypeKey
 * @description Available options
 * - 'primary': A primary button style
 * - 'secondary': A secondary button style
 * - 'tertiary': A tertiary button style
 * - 'destructive': A destructive button style
 * **/
export type ButtonTypeKey = 'primary' | 'secondary' | 'tertiary' | 'destructive';
/**
 * @type {'s', 'm'} - ButtonSizeKey
 * @description Available options
 * - 's': smallest size of button
 * - 'm' biggest size of button
 * **/
export type ButtonSizeKey = 's' | 'm';
/**
 * @type {'default', 'caption'} - ButtonFontTypeKey
 * @description Font weight for link-button
 * - 'default': Use text-ui
 * - 'caption': Use text-caption
 * **/
export type ButtonFontTypeKey = 'default' | 'caption' | '';
/**
 * Enum to represent button types
 * @enum {string}
 * **/
export enum EButtonTypes {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  destructive = 'destructive',
}
/**
 * Enum to represent button sizes
 * @enum {string}
 * **/
export enum EButtonSizes {
  s = 's',
  m = 'm',
}
/**
 * Enum to represent button font type
 * @enum {string}
 * **/
export enum EButtonFontType {
  default = 'default',
  caption = 'caption',
}
export type ColorBtnConfig = { [key: string]: FontColor };
