/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiPromsyConfiguration as __Configuration } from '../api-promsy-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { QueryResultCatDisponibilidadObj } from '../models/query-result-cat-disponibilidad-obj';
import { QueryInfo } from '../models/query-info';
import { QueryResultCatTipoPresentacionObj } from '../models/query-result-cat-tipo-presentacion-obj';
import { QueryResultCatUnidadObj } from '../models/query-result-cat-unidad-obj';
@Injectable({
  providedIn: 'root',
})
class CatalogosService extends __BaseService {
  static readonly catDisponibilidadQueryResultPath = '/catDisponibilidad';
  static readonly catTipoPresentacionQueryResultPath = '/catTipoPresentacion';
  static readonly catUnidadQueryResultPath = '/catUnidad';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * QueryResult catDisponibilidad
   * @param params The `CatalogosService.CatDisponibilidadQueryResultParams` containing the following parameters:
   *
   * - `info`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  catDisponibilidadQueryResultResponse(params: CatalogosService.CatDisponibilidadQueryResultParams): __Observable<__StrictHttpResponse<QueryResultCatDisponibilidadObj>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.info;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/catDisponibilidad`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryResultCatDisponibilidadObj>;
      })
    );
  }
  /**
   * QueryResult catDisponibilidad
   * @param params The `CatalogosService.CatDisponibilidadQueryResultParams` containing the following parameters:
   *
   * - `info`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  catDisponibilidadQueryResult(params: CatalogosService.CatDisponibilidadQueryResultParams): __Observable<QueryResultCatDisponibilidadObj> {
    return this.catDisponibilidadQueryResultResponse(params).pipe(
      __map(_r => _r.body as QueryResultCatDisponibilidadObj)
    );
  }

  /**
   * QueryResult catTipoPresentacion
   * @param params The `CatalogosService.CatTipoPresentacionQueryResultParams` containing the following parameters:
   *
   * - `info`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  catTipoPresentacionQueryResultResponse(params: CatalogosService.CatTipoPresentacionQueryResultParams): __Observable<__StrictHttpResponse<QueryResultCatTipoPresentacionObj>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.info;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/catTipoPresentacion`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryResultCatTipoPresentacionObj>;
      })
    );
  }
  /**
   * QueryResult catTipoPresentacion
   * @param params The `CatalogosService.CatTipoPresentacionQueryResultParams` containing the following parameters:
   *
   * - `info`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  catTipoPresentacionQueryResult(params: CatalogosService.CatTipoPresentacionQueryResultParams): __Observable<QueryResultCatTipoPresentacionObj> {
    return this.catTipoPresentacionQueryResultResponse(params).pipe(
      __map(_r => _r.body as QueryResultCatTipoPresentacionObj)
    );
  }

  /**
   * QueryResult catUnidad
   * @param params The `CatalogosService.CatUnidadQueryResultParams` containing the following parameters:
   *
   * - `info`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  catUnidadQueryResultResponse(params: CatalogosService.CatUnidadQueryResultParams): __Observable<__StrictHttpResponse<QueryResultCatUnidadObj>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.info;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/catUnidad`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryResultCatUnidadObj>;
      })
    );
  }
  /**
   * QueryResult catUnidad
   * @param params The `CatalogosService.CatUnidadQueryResultParams` containing the following parameters:
   *
   * - `info`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  catUnidadQueryResult(params: CatalogosService.CatUnidadQueryResultParams): __Observable<QueryResultCatUnidadObj> {
    return this.catUnidadQueryResultResponse(params).pipe(
      __map(_r => _r.body as QueryResultCatUnidadObj)
    );
  }
}

module CatalogosService {

  /**
   * Parameters for catDisponibilidadQueryResult
   */
  export interface CatDisponibilidadQueryResultParams {
    info: QueryInfo;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for catTipoPresentacionQueryResult
   */
  export interface CatTipoPresentacionQueryResultParams {
    info: QueryInfo;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for catUnidadQueryResult
   */
  export interface CatUnidadQueryResultParams {
    info: QueryInfo;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }
}

export { CatalogosService }
