import { AuthState } from '@app/utils/models/auth/auth.models';
import { AppToken } from '@app/utils/models/auth/token.model';
import { Action, createReducer, on } from '@ngrx/store';
import {
  authLogoutSuccess,
  CLEAR_LOGIN_STATE,
  GET_QR_CODE_SUCCESS,
  getInfoWhoAmiSuccess,
  LOGIN_SUCCESS,
  SET_RETURN_URL,
  SET_TOKEN,
  setIdConnectionHub,
  UPDATE_CODE_STATUS,
  UPDATE_TOKEN,
  VALIDATE_PIN_LOAD,
  VALIDATE_PIN_SUCCESS,
} from './auth.actions';
import { CodeStatus } from '@app/utils/models/mol-confirm-code';
import { UserPromsy } from '@app/utils/models/auth/user-promsy';

export const initialState: AuthState = {
  isAuthenticated: false,
  token: {} as AppToken,
  userInfo: {} as UserPromsy,
  codeAuthStatus: CodeStatus.default,
};

const reducer = createReducer(
  { ...initialState },
  on(
    LOGIN_SUCCESS,
    (state: AuthState, { auth }): AuthState => ({
      ...state,
      isAuthenticated: true,
      token: auth.token,
      userInfo: auth.userInfo,
    }),
  ),
  on(authLogoutSuccess, (state): AuthState => ({ ...state, ...initialState })),
  on(
    SET_TOKEN,
    (state, { auth }): AuthState => ({
      ...state,
      ...auth,
    }),
  ),
  on(UPDATE_TOKEN, (state, { auth }): AuthState => {
    return {
      ...state,
      token: {
        ...auth.token,
      },
    };
  }),
  on(SET_RETURN_URL, (state, { returnUrl }): AuthState => ({ ...state, returnUrl })),
  on(
    GET_QR_CODE_SUCCESS,
    (state, { qrCodeImage }): AuthState => ({
      ...state,
      qrCodeImage,
    }),
  ),
  on(VALIDATE_PIN_LOAD, (state) => ({
    ...state,
    codeAuthStatus: CodeStatus.loading,
  })),
  on(
    VALIDATE_PIN_SUCCESS,
    (state, { value }): AuthState => ({
      ...state,
      codeAuthStatus: value ? CodeStatus.success : CodeStatus.failed,
    }),
  ),
  on(UPDATE_CODE_STATUS, (state, { status }): AuthState => {
    return {
      ...state,
      codeAuthStatus: status,
    };
  }),
  on(
    CLEAR_LOGIN_STATE,
    (): AuthState => ({
      ...initialState,
    }),
  ),
  on(getInfoWhoAmiSuccess, (state, { response }): AuthState => {
    return {
      ...state,
      userInfo: {
        ...state.userInfo,
        ...response,
      },
    };
  }),
  on(setIdConnectionHub, (state, { idConnectionHub }): AuthState => {
    return {
      ...state,
      idConnectionHub: idConnectionHub,
    };
  }),
);

export function authReducer(state: AuthState | undefined, action: Action): AuthState {
  return reducer(state, action);
}
