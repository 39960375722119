import { Component, Input, OnInit } from '@angular/core';
import {
  NgxSkeletonLoaderConfig,
  NgxSkeletonLoaderConfigTheme,
} from 'ngx-skeleton-loader/lib/ngx-skeleton-loader-config.types';
import {
  skeletonDefaultTheme,
  SkeletonFlexDirection,
} from '@app-m-shared/models/atm-skeleton.model';

@Component({
  selector: 'atm-skeleton',
  templateUrl: './atm-skeleton.component.html',
  styleUrls: ['./atm-skeleton.component.scss'],
})
/**
 * @property {NgxSkeletonLoaderConfig['animation']} animation An animation type for skeleton loader
 * @property {NgxSkeletonLoaderConfig['appearance']} appearance An appearance for skeleton loader
 * @property {number} count a number of skeleton loaders
 * @property {flexDirection} flex direction of items of skeleton
 * @property {separation} separation in the items of skeleton
 **/
export class AtmSkeletonComponent implements OnInit {
  /** animations types: false | "progress" | "progress-dark" | "pulse" | "false" **/
  @Input() animation: NgxSkeletonLoaderConfig['animation'] = 'progress';
  /**  appearance types: "" | "circle" | "line" **/
  @Input() appearance: NgxSkeletonLoaderConfig['appearance'] = '';
  /** a number of skeleton loaders (default 1) **/
  @Input() count = 1;
  /** JSON theme to customise loader **/
  @Input() theme: NgxSkeletonLoaderConfigTheme = {};
  /** Flex direction of the skeletons **/
  @Input() flexDirection: SkeletonFlexDirection = 'skeleton-flex-row';
  /** Separation of skeletons items **/
  @Input() separation = 0;

  themeSkeleton: NgxSkeletonLoaderConfigTheme = {};

  ngOnInit(): void {
    this.themeSkeleton = {
      ...this.theme,
      ...skeletonDefaultTheme,
    };
  }
}
