/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiPromsyConfiguration as __Configuration } from '../api-promsy-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { QueryResultVBitacoraPromsyObj } from '../models/query-result-vbitacora-promsy-obj';
import { ResumeGroupQueryInfo } from '../models/resume-group-query-info';
import { ListUsersObj } from '../models/list-users-obj';
import { Message } from '../models/message';
import { ProductoPromsy } from '../models/producto-promsy';
import { VProductoProveedorObj } from '../models/vproducto-proveedor-obj';
import { QueryResultVProductoProveedorObj } from '../models/query-result-vproducto-proveedor-obj';
import { QueryInfo } from '../models/query-info';
import { TotalProductoVigenciaCuraduriaObj } from '../models/total-producto-vigencia-curaduria-obj';
import { VProductoVigenciaCuraduria } from '../models/vproducto-vigencia-curaduria';
import { QueryResultVProductoVigenciaCuraduria } from '../models/query-result-vproducto-vigencia-curaduria';
@Injectable({
  providedIn: 'root',
})
class ConfiguracionProductosService extends __BaseService {
  static readonly BitacoraProductoBitacoraProductoPath = '/BitacoraProducto';
  static readonly BitacoraProductoGetFilterUserPath = '/ObtenerFiltroUsuarioBitacora';
  static readonly NewPromsyProductCreateProductFromPromsyPath = '/CreateProductFromPromsy';
  static readonly ProductoTotalSetProductoTotalPath = '/SetTotalProducts';
  static readonly ValidadorCASProcessPath = '/ValidadorCAS';
  static readonly vProductoObtenerPath = '/vProducto';
  static readonly vProductoQueryResultPath = '/vProductoProveedor';
  static readonly vProductoVigenciaCuraduriaObtenerTotalProductoVigenciaCuraduriaPath = '/TotalProductoVigenciaCuraduria';
  static readonly vProductoVigenciaCuraduriaObtenerTotalProductoVigenciaCuraduria_1Path = '/TotalProductoVigenciaCuraduriaQueryInfo';
  static readonly vProductoVigenciaCuraduriaObtenerPath = '/vProductoVigenciaCuraduria';
  static readonly vProductoVigenciaCuraduriaQueryResultPath = '/vProductoVigenciaCuraduria';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * BitacoraProducto BitacoraProducto
   * @param params The `ConfiguracionProductosService.BitacoraProductoBitacoraProductoParams` containing the following parameters:
   *
   * - `info`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  BitacoraProductoBitacoraProductoResponse(params: ConfiguracionProductosService.BitacoraProductoBitacoraProductoParams): __Observable<__StrictHttpResponse<QueryResultVBitacoraPromsyObj>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.info;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/BitacoraProducto`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryResultVBitacoraPromsyObj>;
      })
    );
  }
  /**
   * BitacoraProducto BitacoraProducto
   * @param params The `ConfiguracionProductosService.BitacoraProductoBitacoraProductoParams` containing the following parameters:
   *
   * - `info`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  BitacoraProductoBitacoraProducto(params: ConfiguracionProductosService.BitacoraProductoBitacoraProductoParams): __Observable<QueryResultVBitacoraPromsyObj> {
    return this.BitacoraProductoBitacoraProductoResponse(params).pipe(
      __map(_r => _r.body as QueryResultVBitacoraPromsyObj)
    );
  }

  /**
   * GetFilterUser BitacoraProducto
   * @param params The `ConfiguracionProductosService.BitacoraProductoGetFilterUserParams` containing the following parameters:
   *
   * - `info`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  BitacoraProductoGetFilterUserResponse(params: ConfiguracionProductosService.BitacoraProductoGetFilterUserParams): __Observable<__StrictHttpResponse<ListUsersObj>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.info;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/ObtenerFiltroUsuarioBitacora`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ListUsersObj>;
      })
    );
  }
  /**
   * GetFilterUser BitacoraProducto
   * @param params The `ConfiguracionProductosService.BitacoraProductoGetFilterUserParams` containing the following parameters:
   *
   * - `info`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  BitacoraProductoGetFilterUser(params: ConfiguracionProductosService.BitacoraProductoGetFilterUserParams): __Observable<ListUsersObj> {
    return this.BitacoraProductoGetFilterUserResponse(params).pipe(
      __map(_r => _r.body as ListUsersObj)
    );
  }

  /**
   * CreateProductFromPromsy NewPromsyProduct
   * @param params The `ConfiguracionProductosService.NewPromsyProductCreateProductFromPromsyParams` containing the following parameters:
   *
   * - `productoPromsy`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  NewPromsyProductCreateProductFromPromsyResponse(params: ConfiguracionProductosService.NewPromsyProductCreateProductFromPromsyParams): __Observable<__StrictHttpResponse<Array<Message>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.productoPromsy;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/CreateProductFromPromsy`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Message>>;
      })
    );
  }
  /**
   * CreateProductFromPromsy NewPromsyProduct
   * @param params The `ConfiguracionProductosService.NewPromsyProductCreateProductFromPromsyParams` containing the following parameters:
   *
   * - `productoPromsy`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  NewPromsyProductCreateProductFromPromsy(params: ConfiguracionProductosService.NewPromsyProductCreateProductFromPromsyParams): __Observable<Array<Message>> {
    return this.NewPromsyProductCreateProductFromPromsyResponse(params).pipe(
      __map(_r => _r.body as Array<Message>)
    );
  }

  /**
   * SetProductoTotal ProductoTotal
   * @param params The `ConfiguracionProductosService.ProductoTotalSetProductoTotalParams` containing the following parameters:
   *
   * - `TotalProducts`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  ProductoTotalSetProductoTotalResponse(params: ConfiguracionProductosService.ProductoTotalSetProductoTotalParams): __Observable<__StrictHttpResponse<Array<Message>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.TotalProducts != null) __params = __params.set('TotalProducts', params.TotalProducts.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/SetTotalProducts`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<Message>>;
      })
    );
  }
  /**
   * SetProductoTotal ProductoTotal
   * @param params The `ConfiguracionProductosService.ProductoTotalSetProductoTotalParams` containing the following parameters:
   *
   * - `TotalProducts`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  ProductoTotalSetProductoTotal(params: ConfiguracionProductosService.ProductoTotalSetProductoTotalParams): __Observable<Array<Message>> {
    return this.ProductoTotalSetProductoTotalResponse(params).pipe(
      __map(_r => _r.body as Array<Message>)
    );
  }

  /**
   * Process ValidadorCAS
   * @param params The `ConfiguracionProductosService.ValidadorCASProcessParams` containing the following parameters:
   *
   * - `cas`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  ValidadorCASProcessResponse(params: ConfiguracionProductosService.ValidadorCASProcessParams): __Observable<__StrictHttpResponse<boolean>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.cas != null) __params = __params.set('cas', params.cas.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/ValidadorCAS`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return (_r as HttpResponse<any>).clone({ body: (_r as HttpResponse<any>).body === 'true' }) as __StrictHttpResponse<boolean>
      })
    );
  }
  /**
   * Process ValidadorCAS
   * @param params The `ConfiguracionProductosService.ValidadorCASProcessParams` containing the following parameters:
   *
   * - `cas`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  ValidadorCASProcess(params: ConfiguracionProductosService.ValidadorCASProcessParams): __Observable<boolean> {
    return this.ValidadorCASProcessResponse(params).pipe(
      __map(_r => _r.body as boolean)
    );
  }

  /**
   * Obtener vProducto
   * @param params The `ConfiguracionProductosService.VProductoObtenerParams` containing the following parameters:
   *
   * - `idProducto`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  vProductoObtenerResponse(params: ConfiguracionProductosService.VProductoObtenerParams): __Observable<__StrictHttpResponse<VProductoProveedorObj>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.idProducto != null) __params = __params.set('idProducto', params.idProducto.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vProducto`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VProductoProveedorObj>;
      })
    );
  }
  /**
   * Obtener vProducto
   * @param params The `ConfiguracionProductosService.VProductoObtenerParams` containing the following parameters:
   *
   * - `idProducto`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  vProductoObtener(params: ConfiguracionProductosService.VProductoObtenerParams): __Observable<VProductoProveedorObj> {
    return this.vProductoObtenerResponse(params).pipe(
      __map(_r => _r.body as VProductoProveedorObj)
    );
  }

  /**
   * QueryResult vProducto
   * @param params The `ConfiguracionProductosService.VProductoQueryResultParams` containing the following parameters:
   *
   * - `info`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  vProductoQueryResultResponse(params: ConfiguracionProductosService.VProductoQueryResultParams): __Observable<__StrictHttpResponse<QueryResultVProductoProveedorObj>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.info;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/vProductoProveedor`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryResultVProductoProveedorObj>;
      })
    );
  }
  /**
   * QueryResult vProducto
   * @param params The `ConfiguracionProductosService.VProductoQueryResultParams` containing the following parameters:
   *
   * - `info`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  vProductoQueryResult(params: ConfiguracionProductosService.VProductoQueryResultParams): __Observable<QueryResultVProductoProveedorObj> {
    return this.vProductoQueryResultResponse(params).pipe(
      __map(_r => _r.body as QueryResultVProductoProveedorObj)
    );
  }

  /**
   * ObtenerTotalProductoVigenciaCuraduria vProductoVigenciaCuraduria
   * @param params The `ConfiguracionProductosService.VProductoVigenciaCuraduriaObtenerTotalProductoVigenciaCuraduriaParams` containing the following parameters:
   *
   * - `idProveedor`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  vProductoVigenciaCuraduriaObtenerTotalProductoVigenciaCuraduriaResponse(params: ConfiguracionProductosService.VProductoVigenciaCuraduriaObtenerTotalProductoVigenciaCuraduriaParams): __Observable<__StrictHttpResponse<TotalProductoVigenciaCuraduriaObj>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.idProveedor != null) __params = __params.set('idProveedor', params.idProveedor.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/TotalProductoVigenciaCuraduria`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TotalProductoVigenciaCuraduriaObj>;
      })
    );
  }
  /**
   * ObtenerTotalProductoVigenciaCuraduria vProductoVigenciaCuraduria
   * @param params The `ConfiguracionProductosService.VProductoVigenciaCuraduriaObtenerTotalProductoVigenciaCuraduriaParams` containing the following parameters:
   *
   * - `idProveedor`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  vProductoVigenciaCuraduriaObtenerTotalProductoVigenciaCuraduria(params: ConfiguracionProductosService.VProductoVigenciaCuraduriaObtenerTotalProductoVigenciaCuraduriaParams): __Observable<TotalProductoVigenciaCuraduriaObj> {
    return this.vProductoVigenciaCuraduriaObtenerTotalProductoVigenciaCuraduriaResponse(params).pipe(
      __map(_r => _r.body as TotalProductoVigenciaCuraduriaObj)
    );
  }

  /**
   * ObtenerTotalProductoVigenciaCuraduria vProductoVigenciaCuraduria
   * @param params The `ConfiguracionProductosService.VProductoVigenciaCuraduriaObtenerTotalProductoVigenciaCuraduria_1Params` containing the following parameters:
   *
   * - `info`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  vProductoVigenciaCuraduriaObtenerTotalProductoVigenciaCuraduria_1Response(params: ConfiguracionProductosService.VProductoVigenciaCuraduriaObtenerTotalProductoVigenciaCuraduria_1Params): __Observable<__StrictHttpResponse<TotalProductoVigenciaCuraduriaObj>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.info;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/TotalProductoVigenciaCuraduriaQueryInfo`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TotalProductoVigenciaCuraduriaObj>;
      })
    );
  }
  /**
   * ObtenerTotalProductoVigenciaCuraduria vProductoVigenciaCuraduria
   * @param params The `ConfiguracionProductosService.VProductoVigenciaCuraduriaObtenerTotalProductoVigenciaCuraduria_1Params` containing the following parameters:
   *
   * - `info`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  vProductoVigenciaCuraduriaObtenerTotalProductoVigenciaCuraduria_1(params: ConfiguracionProductosService.VProductoVigenciaCuraduriaObtenerTotalProductoVigenciaCuraduria_1Params): __Observable<TotalProductoVigenciaCuraduriaObj> {
    return this.vProductoVigenciaCuraduriaObtenerTotalProductoVigenciaCuraduria_1Response(params).pipe(
      __map(_r => _r.body as TotalProductoVigenciaCuraduriaObj)
    );
  }

  /**
   * Obtener vProductoVigenciaCuraduria
   * @param params The `ConfiguracionProductosService.VProductoVigenciaCuraduriaObtenerParams` containing the following parameters:
   *
   * - `idProducto`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  vProductoVigenciaCuraduriaObtenerResponse(params: ConfiguracionProductosService.VProductoVigenciaCuraduriaObtenerParams): __Observable<__StrictHttpResponse<VProductoVigenciaCuraduria>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.idProducto != null) __params = __params.set('idProducto', params.idProducto.toString());
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/vProductoVigenciaCuraduria`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<VProductoVigenciaCuraduria>;
      })
    );
  }
  /**
   * Obtener vProductoVigenciaCuraduria
   * @param params The `ConfiguracionProductosService.VProductoVigenciaCuraduriaObtenerParams` containing the following parameters:
   *
   * - `idProducto`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  vProductoVigenciaCuraduriaObtener(params: ConfiguracionProductosService.VProductoVigenciaCuraduriaObtenerParams): __Observable<VProductoVigenciaCuraduria> {
    return this.vProductoVigenciaCuraduriaObtenerResponse(params).pipe(
      __map(_r => _r.body as VProductoVigenciaCuraduria)
    );
  }

  /**
   * QueryResult vProductoVigenciaCuraduria
   * @param params The `ConfiguracionProductosService.VProductoVigenciaCuraduriaQueryResultParams` containing the following parameters:
   *
   * - `info`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  vProductoVigenciaCuraduriaQueryResultResponse(params: ConfiguracionProductosService.VProductoVigenciaCuraduriaQueryResultParams): __Observable<__StrictHttpResponse<QueryResultVProductoVigenciaCuraduria>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = params.info;
    if (params.Authorization != null) __headers = __headers.set('Authorization', params.Authorization.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/vProductoVigenciaCuraduria`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<QueryResultVProductoVigenciaCuraduria>;
      })
    );
  }
  /**
   * QueryResult vProductoVigenciaCuraduria
   * @param params The `ConfiguracionProductosService.VProductoVigenciaCuraduriaQueryResultParams` containing the following parameters:
   *
   * - `info`:
   *
   * - `Authorization`: Autorización (Ejemplo: "Bearer Token")
   *
   * @return OK
   */
  vProductoVigenciaCuraduriaQueryResult(params: ConfiguracionProductosService.VProductoVigenciaCuraduriaQueryResultParams): __Observable<QueryResultVProductoVigenciaCuraduria> {
    return this.vProductoVigenciaCuraduriaQueryResultResponse(params).pipe(
      __map(_r => _r.body as QueryResultVProductoVigenciaCuraduria)
    );
  }
}

module ConfiguracionProductosService {

  /**
   * Parameters for BitacoraProductoBitacoraProducto
   */
  export interface BitacoraProductoBitacoraProductoParams {
    info: ResumeGroupQueryInfo;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for BitacoraProductoGetFilterUser
   */
  export interface BitacoraProductoGetFilterUserParams {
    info: ResumeGroupQueryInfo;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for NewPromsyProductCreateProductFromPromsy
   */
  export interface NewPromsyProductCreateProductFromPromsyParams {
    productoPromsy: ProductoPromsy;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for ProductoTotalSetProductoTotal
   */
  export interface ProductoTotalSetProductoTotalParams {
    TotalProducts: number;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for ValidadorCASProcess
   */
  export interface ValidadorCASProcessParams {
    cas: string;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for vProductoObtener
   */
  export interface VProductoObtenerParams {
    idProducto: string;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for vProductoQueryResult
   */
  export interface VProductoQueryResultParams {
    info: QueryInfo;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for vProductoVigenciaCuraduriaObtenerTotalProductoVigenciaCuraduria
   */
  export interface VProductoVigenciaCuraduriaObtenerTotalProductoVigenciaCuraduriaParams {
    idProveedor: string;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for vProductoVigenciaCuraduriaObtenerTotalProductoVigenciaCuraduria_1
   */
  export interface VProductoVigenciaCuraduriaObtenerTotalProductoVigenciaCuraduria_1Params {
    info: QueryInfo;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for vProductoVigenciaCuraduriaObtener
   */
  export interface VProductoVigenciaCuraduriaObtenerParams {
    idProducto: string;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }

  /**
   * Parameters for vProductoVigenciaCuraduriaQueryResult
   */
  export interface VProductoVigenciaCuraduriaQueryResultParams {
    info: QueryInfo;

    /**
     * Autorización (Ejemplo: "Bearer Token")
     */
    Authorization?: string;
  }
}

export { ConfiguracionProductosService }
